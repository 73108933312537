import {ApplicationRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {first} from "rxjs/operators";
import {concat, interval} from "rxjs";
import {FirestoreService} from "@core/firestore.service";
import {SubSink} from "subsink";
import {Cry} from "../cry.service";
import {timeOut} from "@core/utils";

@Component({
  selector: 'sw-update',
  templateUrl: './sw-update.component.html',
  styleUrls: ['./sw-update.component.scss']
})
export class SwUpdateComponent implements OnInit, OnDestroy {

  showSnackUpdate = false;
  showModalUpdate = false;
  newVersion: number = null;

  private subs = new SubSink();

  constructor(/*private swUpdate: SwUpdate, appRef: ApplicationRef*/private fss: FirestoreService) {
    /*if ('ServiceWorker' in navigator) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const every30Minutes$ = interval(1800000);
      const every30MinutesOnceAppIsStable$ = concat(appIsStable$, every30Minutes$);

      every30MinutesOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate());
    }*/
  }

  ngOnInit() {
    /*if ('ServiceWorker' in navigator) {
      this.swUpdate.available.subscribe(e => this.showModalUpdate = true);
    }*/
    this.subs.add(
      this.fss.list('versions').orderBy('createdAt', 'desc').limit(10).noNick().obs().subscribe(versions => {
        // console.log('# versions', versions);
        if (versions && versions instanceof Array && versions.length) {
          const oldVersion = Cry.getLocal('ov', true) || null;
          if (oldVersion !== null) {
            if (+oldVersion < +versions[0].version) {
              // console.log('# ', +oldVersion < +versions[0].version);
              this.newVersion = +versions[0].version;
              Cry.set('nv', `${this.newVersion}`, true);
              timeOut(() => this.showModalUpdate = true, 2000);
            }
          } else {
            Cry.setLocal('ov', versions[0].version, true);
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  updateNow() {
    // this.swUpdate.activateUpdate().then(() => (<any>document).location.reload());
    Cry.setLocal('ov', `${this.newVersion}`, true);
    (<any>document).location.reload();
  }

  updateLater() {
    this.showModalUpdate = false;
    this.showSnackUpdate = true;
  }

}
