import { Item } from '@checkout/cart/interface';
import { Address } from '@checkout/addresses/interface';
export class User {
  id: string;
  uid: string;
  email: string;
  username: string;
  userCode: string;
  photoURL?: string;
  displayName?: string;
  firstName?: string;
  role = 'user';
  providerId = 'password';
  cart: Item[];
  wishlist: Item[];
  orderPoints = 0;
  orderQty = 0;
  orderEuros = 0;
  wishQty = 0;
  credits = 0;
  debits = 0;
  balance = 0;
  updatedAt?: string;
  createdAt?: string;
  addresses?: Address[]
  active = false;
  approved = false;
}




