import { timeOut } from '@core/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirestoreService } from './firestore.service';
import { Observable, of, from as fromPromise, Subscription } from 'rxjs';
import { switchMap, first, take, map } from 'rxjs/operators';
import { User } from '@user/interface';
import { NotifyService } from '@shared/notifications/notify.service';
import { Router } from '@angular/router';
import { GlobalService } from "./global.service";
import { Cry } from "@shared/cry.service";
import merge from 'lodash-es/merge';
import { ApiService } from "./api.service";
import firebase from 'firebase/app';
import { EventService } from "@shared/event.service";
import { FirestoreService2 } from './firestore.service2';

@Injectable()
export class AuthService {
  user: Observable<any>
  program;
  location;
  hostname;
  pathname;
  nick
  user2: any;

  uid: string = null;
  userSubscription: Subscription = null;
  isAnonymous: boolean;

  constructor(
    private afAuth: AngularFireAuth,
    public fss: FirestoreService,
    public fss2: FirestoreService2,
    private notify: NotifyService,
    private router: Router,
    private api: ApiService,
    private g: GlobalService,
    public spin: NgxSpinnerService
  ) {
    // console.log('# AuthService::setPersistence()')
    /**
     * Aqui ativamos a persistencia de sessão para os users guest e logados
     */
    this.afAuth.setPersistence('local');

    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.listenUserObjectFromDB(user);
          return this.user2 && this.user2.uid === user.uid ? of(this.user2) : this.fss2.get(`users`, user.uid);
        } else {
          return of(null);
        }
      })
    );

    this.user.subscribe((_user: any) => {
      // console.log(_user)
      // TODO MRS remove this later
      // NOTE always, always test the variable
      if (_user) {
        this.g.set('user', _user)
        if (_user && _user.active) {
          this.user2 = _user
        } else {
          this.user2 = {}
        }

        // if (_user.email && this.g.type !== 'shop')
        //   this.fss.list(`stores`).where([['user', '==', _user.email]]).orderBy('name').promise().then(stores => {
        //     _user.stores = stores
        //     // console.log(_user)
        //     this.g.set('user', _user)
        //     if (_user && _user.active) { this.user2 = _user } else { this.user2 = {} }
        //   })
      }
    })

    this.afAuth.onAuthStateChanged(user => {
      // console.log('#AuthService::onAuthStateChanged()');

      /**
       * Se o User existir
       */
      if (user) {
        if (user.isAnonymous) {
          // console.log('AuthStateChanged - ANONYMOUS: ', user.uid, user.isAnonymous)
          /** Aqui delego a criação (ou não) do guest user a um metodo especifico **/
          this.createGuestUser(user);
          this.isAnonymous = user.isAnonymous
        } else if (!user.isAnonymous) {
          this.isAnonymous = user.isAnonymous
          this.listenUserObjectFromDB(user)
          // console.log('AuthStateChanged - LOGGED: ', user.uid, user.isAnonymous)
        } else {
          // console.log('AuthStateChanged - SIGNOUT: ', user.uid, user.isAnonymous)
        }
      } else {
        /**
         * Se o User não esxistir, faz o login anonimo, porém, por enquanto só há logins
         * anonomos em types SHOP e SITE, os outros programas (PN, ND, FA, CATALOG...) continuam protegidos
         */
        if (this.g.type === 'shop' || this.g.type === 'site' || this.g.type === 'landingPage') {
          console.log('# vai anonymosu login')
          this.anonymousLogin();
        } else this.router.navigate(['/login']);
      }
    })
  }

  defineLoggedUser(user) {
    this.user2 = user;
    this.user = of(user);
  }

  private listenUserObjectFromDB(user: any) {
    if (user.uid !== this.uid) {
      // console.log('#listen id !== uid')
      if (this.userSubscription !== null) this.userSubscription.unsubscribe();
      this.uid = user.uid;
      this.userSubscription = this.fss.read(`${this.g.type}-users`)
        .id(user.uid)
        .obs()
        .pipe(map(us => {
          // console.log('# us', us);
          return us;
        })).subscribe(u => this.g.set('user', u));
    }
  }

  /**
   * Quando um User é anonimo (isAnonymous = true) primeiro, verifica se o mesmo já foi gravado no BD
   * (isso evita apagar o cart caso ele tenha começado uma compra como anonimo), se não existir, então o cria
   * @param user
   */
  private createGuestUser(user: any) {
    this.fss.read(`${this.g.type}-users`)
      .id(user.uid)
      .promise()
      .then(u => {
        if (u === null) {
          const guestUser = {
            cart: [],
            wish: [],
            isAnonymous: true,
            displayName: 'guest', // transloco se encarrega de traduzir nas views
            ballance: 0,
            active: true,
            role: 'user',
            nick: this.g.nick
          };
          // console.log('#create guest')
          this.fss.save(`${this.g.type}-users`).id(user.uid).data(guestUser).promise().then(() => this.g.set('user', { ...guestUser, id: user.uid }));
        } else this.g.set('user', u);
      });
  }

  isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  // wawekew609@gomail4.com
  emailSignUp(user: any, redirect = '', proceedRedirect = true): Promise<any> {
    // this.afAuth.auth.tenantId = 'santini';
    return this.afAuth.createUserWithEmailAndPassword(user.email, user.password)
      .then((credential: any) => {
        return this.updateUserData({ ...user, active: true }, credential)
          .then(newUser => {
            // console.log('createUserWithEmailAndPassword', newUser)
            if (newUser['uid']) {
              this.spin.hide();
              if (proceedRedirect) {
                if (redirect)
                  this.router.navigate([redirect]);
                else if (this.g.get('program'))
                  this.router.navigate([`/${this.g.get('program').type}/home`]);
                else
                  (window as any).location.reload();
              }
              return newUser;
            }
          })
          .catch((e) => {
            this.spin.hide();
            console.log('createUserWithEmailAndPassword - err', credential.user)
            this.api.post(`user/deleteAuth`, credential.user).toPromise().then((data) => console.log(data));
            this.notify.alert('Erro!', `Não foi possivel fazer o registo agora, tente dentro de alguns minutos ou entre em contacto com o suporte ${this.g.program.supportContact}`)
          })
      }).catch(error => {
        this.spin.hide();
        this.notify.update(this.errorMsg(error.code), 'btn-danger', 30000)
        return Promise.reject(this.errorMsg(error.code));
      });
  }

  // Sets user data to firestore on login or signup
  private updateUserData(user, credential) {
    return new Promise((resolve, reject) => {
      timeOut(() => {
        const updUser = new User();
        merge(updUser, credential.user.providerData[0]);
        updUser.id = updUser.uid = credential.user.uid;
        updUser.createdAt = updUser.createdAt || new Date().toISOString();
        merge(updUser, user);
        this.fss.save(`${this.g.type}-users`).id(updUser.id).data(JSON.parse(JSON.stringify(updUser))).promise()
          .then(() => resolve(updUser)).catch(e => reject(e));
        // reject('erro pedro crl')
      }, 500)
    })
    // return updUser;
  }

  githubLogin() {
    const provider = new firebase.auth.GithubAuthProvider();
    return this.oAuthLogin(provider);
  }

  googleLogin(): Promise<any> {
    // const provider = new firebase.auth.GoogleAuthProvider();
    // return this.oAuthLogin(provider);
    return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(async (_user) => {
        // console.log('#google', _user)
        const googleUser = {
          id: _user.user.uid,
          uid: _user.user.uid,
          displayName: _user.user.displayName,
          name: _user.user.displayName,
          firstName: _user.user.displayName.split(' ')[0],
          email: _user.user.email,
          emailVerified: _user.user.emailVerified,
          isAnonymous: _user.user.isAnonymous,
          photo: _user.user.photoURL || null,
          cart: [],
          wishlist: [],
          addresses: [],
          balance: 0,
          nick: this.g.nick,
          active: true,
          role: 'user'
        };
        this.user2 = await this.getUserData(googleUser)
        return this.user2
      }).catch(er => console.log(er.message || er));
  }

  facebookLogin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider).then(credential => {
      this.updateUserData({}, credential.user);
    });
  }

  anonymousLogin() {
    return this.afAuth.signInAnonymously().then((credential: any) => {
      // Cry.set('tempLayout', credential, true);
      console.log('# anonymousLogin', credential.uid);
    }).catch(err => console.log(err.message));
  }

  emailLogin(user): any {
    return this.afAuth.signInWithEmailAndPassword(user.email, user.password)
      .then(async (res) => {
        // console.log('afAuth.auth.signInWithEmailAndPassword: ', res)
        this.user2 = await this.getUserData(res.user ? res.user : res)
        // console.log('getUserData: ', this.user2, res)
        return this.user2
      })
  }

  signOut() {
    this.afAuth.signOut().then(() => {
      // this.g.clear();
      // this.router.navigate(['/login'], { queryParams: { returnUrl: `/${this.nick}/home` } });
      // window.location.reload();
      const url = this.g.type === 'shop' ? `/${this.g.type}/home` : '/login';
      EventService.get('logged-out').emit(true);
      this.userSubscription.unsubscribe();
      this.g.set('user', null);
      this.router.navigate([url], { queryParams: this.g.type !== 'shop' ? { returnUrl: `/${this.nick}/home` } : null });
    });
  }

  // Used by the http interceptor to set the auth header
  getUserIdToken(): Observable<string> {
    // if (this.afAuth && this.afAuth.currentUser) return fromPromise<string>(this.afAuth.currentUser.getIdToken());
    if (this.afAuth && this.afAuth.currentUser) return new Observable<string>(obs => {
      this.afAuth.currentUser.then(user => {
        user.getIdToken().then(token => {
          obs.next(token);
          obs.complete();
        })
      })
    });
    return null;
  }

  async getUserData(_user: any) {
    // console.log('#getUserData', _user)
    return new Promise((resolve, reject) => {
      this.fss2.get(`users`, _user.uid || _user.id, { take: 1 })
        .subscribe((user: any) => {
          // console.log(user)
          if (user) {
            resolve(user);
          } else {
            // console.log('#set userData')
            this.fss.save(`${this.g.type}-users`)
              .id(_user.uid)
              .data(_user)
              .promise()
              .then(u => resolve(u)).catch(er => reject(er));
          }
        });
    })
  }

  async signUpDevelopers(program) {
    const users = [
      { email: 'mrsilva@yesmkt.com', displayName: 'Miguel Ribeiro e Silva', firstName: 'Miguel', password: 'yes4web2020', role: 'admin' },
      { email: 'pedroferreiro@yesmkt.com', displayName: 'Pedro Ferreiro', firstName: 'Pedro', password: 'yes4web2020', role: 'admin' },
      { email: 'waltergandarella@yesmkt.com', displayName: 'Walter Gandarella', firstName: 'Walter', password: 'yes4web2020', role: 'admin' }
    ]
    for (const user of users) {
      await this.emailSignUp(user).then(async user => {
        const body = {
          nick: program.nick,
          email: user.email,
          displayName: user.displayName,
          program: program
        };
        await this.resetPasswordEmail(body)
      })
    }
  }

  // Sends email allowing user to reset password
  resetPasswordDirectFromFirestoreAuth(email: string) {
    const fbAuth = firebase.auth();
    return fbAuth
      .sendPasswordResetEmail(email)
      .then(() => this.notify.update('Email enviado, consulta a tua caixa de correio', 'btn-success', 3000))
      .catch(error => console.log(error));
  }

  resetPasswordEmail(body = null): Promise<any> {
    if (body === null) {
      const _program = {
        name: this.g.program.name,
        nick: this.nick,
        email: this.g.program.email,
        sender: this.g.program.sender,
        imgHeader: this.g.program.images['emailHeader'],
        imgFooter: this.g.program.images['emailFooter'],
        templateId: this.g.program.emails['resetPassword'],
        url: this.g.program.url
      };
      body = {
        nick: this.nick,
        email: this.user2.email,
        displayName: this.user2.displayName,
        program: _program
      };
    }

    const data = { email: body.email, expires: new Date().getTime() + (60 * 60 * 1000) };
    const expires = Cry.crypt(JSON.stringify(data), true);
    const host = (window as any).location.hostname;
    const link = `${host}${host.indexOf('yes4') > -1 ? ':4400' : ''}/login/resetPassword/${expires}`;
    // console.log('user/resetPassword', { ...body, link })
    return this.api.post('user/resetPassword', { ...body, link })
      .toPromise()
      .then(() => this.notify.update('Email enviado, consulta a tua caixa de correio', 'btn-success', 3000));
  }

  async isRegistered(email) {
    this.spin.show();
    const res = await this.api.post('user/isRegistered', { nick: this.fss.getNick('users'), email: email }).toPromise();
    this.spin.hide();
    return res;
  }

  isSuperUser = (user: any) => {
    return [
      'mrsilva@yesmkt.com',
      'waltergandarella@yesmkt.com',
      'pedroferreiro@yesmkt.com'
    ].indexOf(user.email) > -1;
  }

  verifyRoles = (roles: string[], user: any) => {
    return user !== null && roles.indexOf(user.role) > -1;
  }

  deleteAnonymousUser(anonymousUser: any) {
    if (anonymousUser && anonymousUser.id)
      this.fss.read(`${this.g.type}-users`).id(anonymousUser.id).promise().then(au => {
        if (au && au.isAnonymous) this.fss.delete(`${this.g.type}-users`).id(anonymousUser.id).promise().then(() => console.log('# excluido')).catch(err => console.log(err.message))
      });
  }

  createUserVisit() {
    setTimeout(() => {
      let device: string;
      if (this.g.isDesktop) device = 'PC'
      if (this.g.isTablet && this.g.os !== 'Mac') device = 'Tablet'
      if (this.g.isTablet && this.g.os === 'Mac') device = 'Ipad'
      if (this.g.isMobile && this.g.os !== 'iOS') device = 'Smartphone'
      if (this.g.isMobile && this.g.os === 'iOS') device = 'Iphone'
      if (this.user2 && this.user2.email) {
        const dt = new Date();
        dt.setUTCHours(0, 0, 0, 0);
        this.fss.list(`logs`).where([['timestamp', '>=', dt.getTime()], ['type', '==', 'access'], ['email', '==', this.user2.email]]).promise().then((logs: any[]) => {

          if (!logs.length) {
            const visit = {
              displayName: this.user2.displayName,
              email: this.user2.email,
              segment: this.user2.segment,
              device: device,
              createdAt: new Date().toISOString(),
              timestamp: new Date().getTime(),
              type: 'access'
            }
            // console.log(visit)
            this.fss.save(`logs`).data(visit).promise().then(res => { }).catch(err => console.log(err))
          }
        })
      }
    }, 3000);
  }

  // TODO MRS this is bad! maybe internationalization will fix it
  errorMsg(code): string {
    switch (code) {
      case 'auth/user-not-found':
        return 'Este email não está ainda registado.';
      // Thrown if the instance of FirebaseApp has been deleted.
      case 'auth/app-deleted':
        return 'Aplicação não ativa.';
      // Thrown if the instance of FirebaseApp has been deleted.
      case 'auth/app-not-authorized':
        return 'Acesso à base de dados não autorizado.';
      // Thrown if the app identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API // console.
      case 'auth/argument-error':
        return 'Erro no pedido de autenticação.';
      // Thrown if a method is called with incorrect arguments.
      case 'auth/invalid-api-key':
        return 'Erro no acesso ao Firebase.';
      // Thrown if the provided API key is invalid. Please check that you have copied it correctly from the Firebase // console.
      case 'auth/invalid-user-token':
        return 'As suas credenciais já não são válidas. Por favor, faça ogin de novo.';
      // Thrown if the user's credential is no longer valid. The user must sign in again.
      case 'auth/network-request-failed':
        return 'A ligação através da sua rede não foi possível.';
      // Thrown if a network error (such as timeout, interrupted connection or unreachable host) has occurred.
      case 'auth/operation-not-allowed':
        return 'Não pode autenticar-se através desse método.';
      // Thrown if you have not enabled the provider in the Firebase // console. Go to the Firebase Console for your project, in the Auth section and the Sign in Method tab and configure the provider.
      case 'auth/requires-recent-login':
        return 'Foi ultrapassado o limite de segurança desde o seu último login';
      // Thrown if the user's last sign-in time does not meet the security threshold. Use firebase.User#reauthenticateWithCredential to resolve. This does not apply if the user is anonymous.
      case 'auth/too-many-requests':
        return 'Bloqueado por atividade pouco usual. Por favor, tente mais tarde.';
      // Thrown if requests are blocked from a device due to unusual activity. Trying again after some delay would unblock.
      case 'auth/unauthorized-domain':
        return 'Esta aplicação não está autorizada para autenticação.';
      // Thrown if the app domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase // console.
      case 'auth/user-disabled':
        return 'Erro desconhecido';
      // Thrown if the user account has been disabled by an administrator. Accounts can be enabled or disabled in the Firebase Console, the Auth section and Users subsection.
      case 'auth/user-token-expired':
        return 'As suas credenciais expiraram. Por favor, tente de novo.';
      // Thrown if the user's credential has expired. This could also be thrown if a user has been deleted. Prompting the user to sign in again should resolve this for either case.
      case 'auth/web-storage-unsupported':
        return 'O seu browser não suporta as funcionalidades (web storage) necessárias ao bom funcionamento desta aplicação.';
      // Thrown if the browser does not support web storage or if the user disables them.
      case 'auth/wrong-password':
        return 'Palavra-passe inválida.';
      // Thrown if there already exists an account with the given email address.
      case 'auth/email-already-in-use':
        return 'Este email já está registado. Faça login.';
      // Thrown if the email address is not valid.
      case 'auth/invalid-email':
        return 'Email inválido.';
      // Thrown if the password is not strong enough.
      case '400':
        return 'Email inválido.';
      // Thrown if the password is not strong enough.
      case 'auth/weak-password':
        return 'A password é fraca, deve ter pelo menos 6 carateres.';
      // Thrown if there already exists an account with the email address asserted by the credential.
      case 'auth/account-exists-with-different-credential':
        return 'Este email já está registado. Faça login.';
      // Thrown if authDomain configuration is not provided when calling firebase.initializeApp().
      // Check Firebase Console for instructions on determining and passing that field.
      case 'auth/auth-domain-config-required':
        return 'Não houve configuração inicial para operações de autenticação.';
      // Thrown typically if the app domain is not authorized for OAuth operations for your Firebase project.
      // Edit the list of authorized domains from the Firebase // console.
      case 'auth/timeout':
        return 'Excesso de tempo na autenticação.';

      default:
        return 'Erro desconhecido';
    }
  }

}
