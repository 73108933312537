<div *ngIf="notify.msg | async as msg" class="fixed-bottom notification-component" style="z-index: 9999">

  <!--<div class="btn-group btn-sm pull-right w-100 {{msg.style}}">-->
  <div class="flex w-100 {{msg.style}}">

    <button class="delete btn btn-sm {{msg.style}}" (click)="notify.clear()">
      <i class="fa fa-times"></i>
    </button>

    <button class="btn btn-sm flex-grow-1 {{msg.style}}">
      <b>{{ msg.content }}<br>{{ msg.content2 }}</b>
    </button>

    <a *ngIf="msg.link" class="btn {{msg.style}}" href="{{msg.link?.url}}">
      <i class="fa fa-link">&nbsp;{{msg.link?.name}}</i>
    </a>

  </div>

</div>

<notification-generic-modal [config]="modalData" (resuult)="modalResult($event)" [show]="modalShow"></notification-generic-modal>
