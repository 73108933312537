import {NgModule} from "@angular/core";
import {NotificationsComponent} from "./notifications";
import {NotificationGenericModalComponent} from "./notification-generic-modal.component";
import {CommonModule} from "@angular/common";
import {NgbModalModule} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmModalComponent} from "./confirm-modal.component";

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationGenericModalComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule
  ],
  exports: [
    NotificationsComponent
  ],
  entryComponents: [
    ConfirmModalComponent
  ]
})
export class NotificationsModule {
}
