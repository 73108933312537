import { KeyValue } from '@angular/common';
import isEqual from 'lodash-es/isEqual'


export const pad = (str, max, fill?) => {
  str = str.toString();
  return str.length < max ? pad(fill || "0" + str, max, fill) : str;
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const treeify = (collection: any[], nick: string = null) => {
  // create a name: node map
  const dataMap = collection.reduce((map, node) => ({ ...map, [node.name]: node }), {});
  // create the tree collection
  const treeData = [];
  collection.forEach((node: any) => {
    if (node.active && (!node[nick] || node[nick] === false)) {
      // add to parent
      const parent = dataMap[node.parent];
      if (parent) {
        // create child collection if it doesn't exist
        (parent.children || (parent.children = []))
          // add node to child collection
          .push(node);
      } else {
        // parent is null or missing
        treeData.push(node);
      }
    }
  });
  return treeData[0].children;
};

export const treeify2 = (collection: any[], nick: string = null) => {
  // create a path: node map
  const dataMap = collection.reduce((map, node) => {
    map[node.path] = node;
    return map;
  }, {});
  // create the tree collection
  const treeData = [];
  collection.forEach((node: any) => {
    if (node.active && (!node[nick] || node[nick] === false)) {
      // add to parent
      const parent = dataMap[node.parent];
      if (parent) {
        // create child collection if it doesn't exist
        (parent.children || (parent.children = []))
          // add node to child collection
          .push(node);
      } else {
        // parent is null or missing
        treeData.push(node);
      }
    }
  });
  return treeData[0].children
};

export const orientation = () => {
  if (window.matchMedia("(orientation: landscape)").matches) {
    return 'landscape'
  } else if (window.matchMedia("(orientation: portrait)")) {
    return 'portrait'
  }
}

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key])
      return false;
  }
  return true;
}

import merge from 'lodash-es/merge'
import { NgZone } from "@angular/core";
/*
  *
  * @function constructObject
  * @param {file} file in dot notation ('a.b.c':'Hey', 'd.e.g': 'you')
  * @return {Object} - a nested object.
  * @example
  * const object = constructObject(file)
  * Returns the following:
  * a: {
  *   b: {
  *     c: 'hey'
  *   }
  * }
  * d: {
  *   e: {
  *     g: 'you'
  *   }
  * }
*/
export function objectToArray(obj, idName?, valueName?): any {
  const array = [];
  for (const prop in obj) {
    if (obj[prop]) {
      const newObj = {}
      newObj[idName || 'id'] = prop
      newObj[valueName || 'value'] = obj[prop]
      array.push(newObj)
    }
  }
  return array;
}

export const arrayToObject = (array, field) => {
  return array.reduce((obj, item) => {
    obj[item[field]] = item
    return obj
  }, {})
}

// Get the nested object/key without the value
const getKey = (arr) => {
  const nestedObject = {}
  arr.reduce((o, s, index) => {
    if (index + 1 < arr.length) {
      return o[s] = {}
    }
  }, nestedObject) // eslint-disable-line
  return nestedObject
}

// Get the full object here
const getNested = (arr, val) => {
  const nestedObject = {}
  arr.reduce((o, s, index) => {
    if (index + 1 === arr.length) {
      return o[s] = val
    } else {
      return o[s] = {}
    }
  }, nestedObject) // eslint-disable-line
  return nestedObject
}

export const getNestedObject = (file) => {
  let final = {}
  const parsed = JSON.parse(JSON.stringify(file))
  Object.keys(parsed).forEach((key) => {
    const keysArr = key.split('.')
    if (keysArr.length > 1) {
      final = merge(final, getKey(keysArr), getNested(keysArr, parsed[key]))
    } else { final[keysArr[0]] = parsed[key] }
  })
  return final
}

export const excelDateToISOdate = (date) => {
  const d = new Date(Math.round((date - 25569) * 86400 * 1000));
  return d.toISOString();
}

// export default constructObject
export const originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return 0;
}

// Order by ascending property value
export const valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return a.value.localeCompare(b.value);
}

// Order by descending property key
export const keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
  return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
}

export const filterObject = (obj, filter, filterValue) =>
  Object.keys(obj).reduce((acc, val) => (obj[val][filter] === filterValue ? { ...acc, [val]: obj[val] } : acc), {});

export const formatDate = (date) => {
  const d = new Date(date),
    year = d.getFullYear();
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

/** setTimeout using NgZone, better for performance **/
export const timeOut = (fn: Function, ms: number = 10, zone = new NgZone({ enableLongStackTrace: false })) => zone.runOutsideAngular(() => setTimeout(() => zone.run(() => fn()), ms));

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export const getObjectDiff = (obj1, obj2) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));
}

export const deepFreeze = o => {
  Object.freeze(o);

  Object.getOwnPropertyNames(o).forEach(prop => {
    if (o.hasOwnProperty(prop)
      && o[prop] !== null
      && (typeof o[prop] === "object" || typeof o[prop] === "function")
      && !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop]);
    }
  });

  return o;
};

export const clearFieldsStartedBy = (obj: any, startedBy: string) => {
  const tmp = {};
  for (const key in obj) if (!key.startsWith(startedBy)) tmp[key] = obj[key];
  return tmp;
};

export const clearFieldsStartedBy2 = (obj: any, startedBy: string) => {
  let temp;

  if (obj instanceof Array) {
    temp = [];
    for (const item of obj) {
      temp.push(clearFieldsStartedBy2(item, startedBy));
    }
  } else {
    temp = {};
    for (const key in obj) {
      if (!key.startsWith(startedBy)) {
        if (typeof obj[key] === 'object') temp[key] = clearFieldsStartedBy2(obj[key], startedBy);
        else temp[key] = obj[key];
      }
    }
  }

  return temp;
};

export const sumBy = (arr: any[], prop: string) => arr.map(i => i[prop]).reduce((acc, val) => acc + val, 0);
