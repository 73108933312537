import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { translocoLoader } from './transloco.loader';
import { StyleLoader } from './style.loader';
import { ThemeService } from '@core/theme.service';
import { AuthService } from '@core/auth.service';
import { GlobalService } from '@core/global.service';
import { FirestoreService } from '@core/firestore.service'
import { NotificationsModule } from '@shared/notifications/module';
import { HttpClientModule } from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, LOCALE_ID, APP_INITIALIZER, ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import ptPT from '@angular/common/locales/pt-PT';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { env } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PagesComponent } from '@shared/pages/page';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SwUpdateComponent } from '@shared/sw-update/sw-update.component';
import { AdblockModalComponent } from '@shared/adblock-modal/adblock-modal.component';
import { GdprVerifyComponent } from '@shared/gdpr-verify/gdpr-verify.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { TranslocoModule, TRANSLOCO_CONFIG, TranslocoConfig } from '@ngneat/transloco';
import { AuthGuard } from '@core/auth.guard';
import { TokenInterceptor } from '@core/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotFoundComponent } from "@shared/not-found/not-found.component";
import { MyAccountModule } from './my-account/module';
import { CheckoutModule } from '@checkout/module';
import {YesErrorHandler} from "@core/yes-error.handler";
import { DialogModule } from "primeng/dialog";

export function initApp(g: GlobalService) { return () => g.initApp(); }

registerLocaleData(ptPT)

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    SwUpdateComponent,
    AdblockModalComponent,
    GdprVerifyComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    AngularFireModule.initializeApp(env.firebaseConfig),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgxSpinnerModule,
    NotificationsModule,
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: env.production }),
    DeviceDetectorModule.forRoot(),
    TranslocoModule,
    MyAccountModule,
    CheckoutModule,
    DialogModule
  ],
  providers: [
    env.production ? { provide: ErrorHandler, useClass: YesErrorHandler } : ErrorHandler,
    FirestoreService,
    AuthService,
    ThemeService,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    GlobalService,
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [GlobalService], multi: true },
    StyleLoader,
    translocoLoader,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: ['pt-PT'], // availableLangs.map(lang => lang.name),
        defaultLang: 'pt-PT', // defaultLang,
        fallbackLang: `pt-PT`,
        prodMode: env.production,
        reRenderOnLangChange: true
      } as TranslocoConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
