import { GlobalService } from '@core/global.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class EmailService {
  type: string;
  nick: string;
  apiUrl: string;
  count: number;
  program: any;

  constructor(
    private http: HttpClient,
    private g: GlobalService
  ) {
    this.nick = this.g.get('nick');
    this.program = this.g.get('program');
    this.type = this.g.get('type');
    this.apiUrl = this.g.get('apiUrl')
  }

  emailProgram() {
    return {
      name: this.g.get('program').name,
      url: this.g.get('program').url,
      sender: this.g.get('program').sender,
      email: this.g.get('program').email,
      imgHeader: this.g.get('program').images.emailHeader,
      imgFooter: this.g.get('program').images.emailFooter,
      xrate: this.g.get('program').xrate,
      subject: '',
      templateId: '99'
    }
  }

  sendMany(items: any[], program: any) {
    return this.http.post<any>(`${this.apiUrl}/sendinblue/sendMany`, { items: items, program: program });
  }

  sendOne(item: any, program: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/sendinblue/sendOne`, { item: item, program: program });
  }

  sendConfirmationCardLoadEmail(card, user: any = null): Observable<any> {
    const _program = {
      name: this.program.name,
      sender: this.program.sender,
      email: this.program.email,
      url: this.program.url,
      subject: `Registo do carregamento do ${card.name} nº ${card.cardId}`,
      html: `O carregamento do ${card.name} nº ${card.cardId} ficou registado na plataforma ${this.program.name} e no prazo de 10 dias úteis o carregamento será efectuado.<br><br>
      <b>Muito importante:</b>
      <br>Por favor confirma o nº série do cartão e caso este não coincida com o cartão que tens em teu poder, liga-nos para o  21 406 76 94<br><br>
      Caso tenhas alguma dúvida, contacta o <b>serviço de apoio</b>.<br>
      (Disponível de segunda a sexta das 9h30 às 13:00 e das 14:00 às 18h00)<br>`,
      imgHeader: this.program.images['emailHeader'],
      imgFooter: this.program.images['emailFooter'],
      templateId: this.program.emails['generic']
    };

    const _item = {
      displayName: user.displayName,
      email: user.email,
    };
    const endpoint = this.apiUrl + '/sendinblue/sendOne';
    return this.http.post(endpoint, { item: _item, program: _program });
  }

  sendConfirmationOrderEmail(order: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/sendinblue/sendOrderConfirmation`, order);
  }

  sendPaymentOk(order: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/sendinblue/sendPaymentOk`, order);
  }

  sendPaymentPending(order: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/sendinblue/sendPaymentPending`, order);
  }

}
