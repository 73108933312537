import { AuthService } from '@core/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'my-account-component',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.sass']
})

export class MyAccountComponent implements OnInit {


  constructor(
    public auth: AuthService,
    private router: Router
  ) {  }

  ngOnInit() {

  }

}
