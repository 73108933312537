import { GlobalService } from './global.service';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NotifyService } from '@shared/notifications/notify.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  auth: AuthService;

  constructor(
    private inj: Injector,
    private g: GlobalService,
    private notify: NotifyService,
    // private afAuth: AngularFireAuth
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('intercept: ', this.afAuth.auth.currentUser, request.url)
    if (
      request.url.indexOf('youtube') > -1 ||
      request.url.indexOf('generated.photos') > -1 ||
      request.url.indexOf('shop') > -1 ||
      request.url.indexOf('site') > -1 ||
      request.url.indexOf('oauthCallback') > -1 ||
      request.url.indexOf('resetPassword') > -1 ||
      request.url.indexOf('isRegistered') > -1 ||
      request.url.indexOf('/assets/') > -1 ||
      request.url.indexOf('noAuthRequest') > -1 ||
      request.url.indexOf(`programs/${this.g.get('nick')}`) > -1
    ) {
      return next.handle(request)
        .pipe(catchError(err => {
          if (err && request.url.toLowerCase().indexOf(this.g.apiUrl) > -1) {
            // faz alguma coisa aqui para avisar ao utilizador que a rede esta a bloquear
            this.notify.alert('Erro!', 'Aparentemente a rede com que está connectado está a bloquear o nosso site. Tente novamente a partir de outra rede.')
          }
          return throwError(err);
        }));
    }

    this.auth = this.inj.get(AuthService) // inject the authservice manually (see https://github.com/angular/angular/issues/18224)

    return this.auth.getUserIdToken().pipe(
      switchMap(token => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        // console.log(request)
        return next.handle(request)
          .pipe(catchError(err => {
            if (err && request.url.toLowerCase().indexOf(this.g.apiUrl) > -1) {
              // faz alguma coisa aqui para avisar ao utilizador que a rede esta a bloquear
              this.notify.alert('Erro!', 'Aparentemente a rede com que está connectado está a bloquear o nosso site. Tente novamente a partir de outra rede.')
            }
            return throwError(err);
          }));
      })
    )
  }
}
