import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {SubSink} from "subsink";

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.sass']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  from = '';
  private subs = new SubSink();

  constructor(private router: Router) { }

  ngOnInit() {
    this.from = this.router.url;
    this.subs.add(
      this.router.events.subscribe(
        (event: any) => {
          if (event instanceof NavigationEnd) {
            this.from = event.url;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
