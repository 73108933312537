import { GlobalService } from './global.service';
import { Injectable } from '@angular/core';
import { map, tap, expand, takeWhile, mergeMap, take } from 'rxjs/operators'
import { Observable, from as fromPromise, of } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { NotifyService } from '@shared/notifications/notify.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentChangeAction } from '@angular/fire/firestore';
import {
  catchObsError,
  catchPromiseError,
  CollectionPredicate,
  defineObjectNick,
  DocPredicate,
  docWithId,
  getOpts,
  getPath,
  getNick,
  IArgsList,
  valuesWithId, defineAfs, col, doc
} from "@core/firestore.utils";

@Injectable({ providedIn: 'root' })
export class FirestoreService {
  collections;
  fs: any = null;

  getOpts = getOpts;
  getPath = getPath;
  getNick = getNick;

  constructor(
    public afs: AngularFirestore,
    private notify: NotifyService,
    private g: GlobalService
  ) {
    this.fs = firebase.firestore();
    defineAfs(afs);
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp()
  }

  get now() {
    const d = new Date();
    return d.toISOString();
  }

  createId() {
    return this.fs.collection('_').doc().id;
  }

  private getUpdatedBy(data: any) {
    if (this.g.user) {
      data['updatedBy'] = this.g.user.email || data.updatedBy || 'guest';
    }
    return data;
  }

  // adapted from http://masteringionic.com/blog/2017-10-22-using-firebase-cloud-firestore-with-ionic/ (PROMISES)
  // adapted from https://angularfirebase.com/lessons/firestore-advanced-usage-angularfire/ (OBSERVABLES)

  /// ***************** Get Data *****************

  // @catchObsError()
  count(ref: CollectionPredicate<any>, queryFn?): Observable<any[]> {
    return col(ref, queryFn).snapshotChanges()
      .pipe(map(docs => docs.map(a => a.payload.doc.data().length) as any[]));
  }

  geopoint(lat: number, lng: number) {
    return new firebase.firestore.GeoPoint(lat, lng)
  }

  /// **************
  /// Inspect Data
  /// **************
  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime()
    doc(ref).snapshotChanges()
      .pipe(take(1),
        tap(d => {
          const tock = new Date().getTime() - tick
          // console.log(`Loaded Document in ${tock}ms`, d)
        }))
      .subscribe()
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime()
    col(ref).snapshotChanges()
      .pipe(take(1))
      .pipe(tap(c => {
        const tock = new Date().getTime() - tick
        // console.log(`Loaded Collection in ${tock}ms`, c)
      }))
      .subscribe()
  }

  /// **************
  /// Create and read doc references
  /// **************

  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, _doc: DocPredicate<any>) {
    return doc(host).update({ [key]: doc(_doc).ref })
  }

  /// returns a documents references mapped to AngularFirestoreDocument
  // @catchObsError()
  docWithRefs$<T>(ref: DocPredicate<T>, options: any = { forwardError: false }) {
    return this.doc$(ref).pipe(map(_doc => {
      for (const k of Object.keys(_doc)) {
        if (_doc[k] instanceof firebase.firestore.DocumentReference) {
          _doc[k] = doc(_doc[k].path)
        }
      }
      return _doc;
    }))
  }

  // @catchObsError()
  doc$<T>(ref: DocPredicate<T>, options: any = { forwardError: false }): Observable<T> {
    // console.log(ref)
    return doc(ref).snapshotChanges().pipe(map(doc => {
      return doc.payload.data() as T
    }))
  }

  // Deleting Firestore Collections in Angular (https://angularfirebase.com/lessons/delete-firestore-collections-with-angular-and-rxjs/)
  // @catchObsError()
  deleteCollection(path: string, batchSize: number, options: any = { forwardError: false }): Observable<any> {
    const source = this.deleteBatch(path, batchSize)
    // expand will call deleteBatch recursively until the collection is deleted
    return source.pipe(
      expand(val => this.deleteBatch(path, batchSize)),
      takeWhile(val => val > 0)
    )
  }

  // Deletes documents as batched transaction
  // @catchObsError()
  private deleteBatch(path: string, batchSize: number, options: any = { forwardError: false }): Observable<any> {
    const colRef = this.afs.collection(path, ref => ref.orderBy('__name__').limit(batchSize))
    return colRef.snapshotChanges().pipe(
      take(1),
      mergeMap(snapshot => {
        // Delete documents in a batch
        const batch = this.afs.firestore.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.payload.doc.ref);
        });
        return fromPromise(batch.commit()).pipe(map(() => snapshot.length))
      })
    )
  }

  sendErrMsg(error, collection?) {
    // console.log('Não tem autorização para realizar esta operação: ' + collection)
    if (error.code === 'permission-denied') {
      this.notify.update('Não tem autorização para realizar esta operação: ' + collection,
        'btn-danger', 3000);
    }
  }

  // ***********************************************
  // * Métodos antigos temporariamente desativados *
  // * Devem ser removidos posteriormente          *
  // ***********************************************

  // @catchObsError()
  // read(collection, id, args: Partial<IArgsList> = { forwardError: false }): Observable<any> {
  //   const data = this.defineObjectNick({ id: id }, collection, {})
  //   // const nick = this.getNick(collection)
  //   const opts = this.getOpts(collection)
  //   // console.log('# getOpts', collection, opts.path, id)
  //   return this.doc(`${opts.path}/${data.id}`).snapshotChanges().pipe(args.take ? take(+args.take + 1) : map(actions => actions), docWithId);
  // }

  // @catchObsError()
  // list(collection, args: Partial<IArgsList> = { forwardError: false }): Observable<any[] | any> {
  //   const opts = this.getOpts(collection)
  //   // console.log('# getOpts', collection, opts.path)
  //
  //   return this.col(opts.path, ref => {
  //     if (args.where && args.where !== null) {
  //       if (args.where instanceof Array) {
  //         for (const where of args.where) {
  //           if (where[2] === undefined)
  //             throw new Error(`Consulta na collection '${collection}' não pode ter um WHERE cujo valor seja UNDEFINED (${where[0]} ${where[1]} undefined)`);
  //           else ref = ref.where(where[0], where[1], where[2]);
  //         }
  //       }
  //     }
  //     if (!opts.noNick && !args.noNick) {
  //       ref = ref.where('nick', '==', opts.nick);
  //     }
  //     if (args.orderBy && args.orderBy !== null) {
  //       const dir = args.direction && args.direction ? args.direction : 'asc';
  //       ref = ref.orderBy(args.orderBy, dir);
  //     }
  //     // if (args['lastItem']) {
  //     //   ref = ref.startAfter(args.lastItem);
  //     // }
  //     if (args.limit) ref = ref.limit(args.limit);
  //     if (collection === 'users' || collection === 'sales-users') console.log('#ref', collection, opts.path, ref)
  //     // console.log('# ref', ref)
  //     return ref;
  //   }).snapshotChanges().pipe(args.take === 1 ? take(1) : map(actions => actions), valuesWithId);
  //
  // };

  // @catchPromiseError()
  // list2(collection, args: Partial<IArgsList> = { forwardError: false }): Promise<any> {
  //   /*return new Promise((resolve, reject) => {
  //     this.fs.collection(collectionObj)
  //       .get()
  //       .then((querySnapshot) => {
  //         // Declare an array which we'll use to store retrieved documents
  //         const obj: any = [];
  //         // Iterate through each document, retrieve the values for each field
  //         // and then assign these to a key in an object that is pushed into the
  //         // obj array
  //         querySnapshot
  //           .forEach((doc: any) => {
  //             obj.push({
  //               ...doc.data(),
  //               id: doc.id,
  //             });
  //           });
  //         // Resolve the completed array that contains all of the formatted data
  //         // from the retrieved documents
  //         resolve(obj);
  //       })
  //       .catch((error: any) => {
  //         reject(error);
  //       });
  //   });*/
  //   const opts = this.getOpts(collection);
  //   // console.log('# getOpts', collection, opts.path)
  //   let listpromise = this.fs.collection(opts.path); // .where('active', '==', true);
  //   if (args.where && args.where !== null) {
  //     if (args.where instanceof Array) {
  //       for (const where of args.where) {
  //         if (where[2] === undefined)
  //           throw new Error(`Consulta na collection '${collection}' não pode ter um WHERE cujo valor seja UNDEFINED (${where[0]} ${where[1]} undefined)`);
  //         else listpromise = listpromise.where(where[0], where[1], where[2]);
  //       }
  //     }
  //   }
  //   if (!opts.noNick && !args.noNick) {
  //     listpromise = listpromise.where('nick', '==', opts.nick);
  //   }
  //   if (args.orderBy && args.orderBy !== null) {
  //     const dir = args.direction && args.direction ? args.direction : 'asc';
  //     listpromise = listpromise.orderBy(args.orderBy, dir);
  //   }
  //   if (args.limit) listpromise = listpromise.limit(args.limit);
  //   return listpromise.get().then(snapshot => {
  //     const items = [];
  //     snapshot.docs.forEach((doc: any) => items.push({ ...doc.data(), id: doc.id }));
  //     return items;
  //   });
  // }

  // esta versão não verifica o nick e só deverá ser usada para marcar as collections com o nick, preparando a migração para uma só collection
  // @catchObsError()
  // list3(collection, args: any = { forwardError: false }): Observable<any[] | any> {
  //   const opts = this.getOpts(collection, args)
  //   if (collection === 'transactions') console.log('list3', opts.nick, collection, opts.path)
  //   return this.col(opts.path, ref => {
  //     if (collection === 'categories') console.log('list3', ref)
  //     return ref;
  //   }).snapshotChanges().pipe(args.take ? take(+args.take + 1) : map(actions => actions), valuesWithId);
  //   // NOTE PF I changed all occorrences of the 3 methods with 'take' ... so, be careful
  // };

  // @catchPromiseError()
  // update(collection: string, id: string, data: any, options: any = { forwardError: false }): Promise<any> {
  //   // console.log('#update2', collection, data, id)
  //   data = JSON.parse(JSON.stringify(data));
  //   data.updatedAt = this.now;
  //   data.id = id
  //   data = this.defineObjectNick(data, collection, options);
  //   data = this.getUpdatedBy(data);
  //   // const nick = this.getNick(collection)
  //   const opts = this.getOpts(collection)
  //   // console.log('#update', opts.path, data.id, data)
  //   return this.fs
  //     .collection(opts.path)
  //     .doc(data.id)
  //     .update(data);
  // }

  // @catchPromiseError()
  // add(collection: string, data: any, options: any = { forwardError: false }): Promise<any> {
  //   // const nick = this.getNick(collection)
  //   const opts = this.getOpts(collection)
  //   data = this.defineObjectNick(data, collection, options);
  //   data = this.getUpdatedBy(data);
  //   // console.log(opts.path, data)
  //   return this.fs.collection(opts.path).add(data);
  // }

  // @catchPromiseError()
  // set(collection: string, id: string, data: any, options: any = { forwardError: false }): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     // const nick = this.getNick(collection)
  //     const opts = this.getOpts(collection)
  //     data = this.defineObjectNick(data, collection, options);
  //     data.id = id
  //     data = this.getUpdatedBy(data);
  //     // console.log(opts.path, data)
  //     // console.log(opts.path, id, data)
  //     return this.fs.collection(opts.path).doc(id).set(data, { merge: true })
  //       .then((data: any) => {
  //         resolve(data);
  //       })
  //       .catch((error: any) => {
  //         this.sendErrMsg(error);
  //         reject(error);
  //       });
  //   });
  // }

  // @catchPromiseError()
  // delete(collection: string, id: string, options: any = { forwardError: false }): Promise<any> {
  //   const opts = this.getOpts(collection)
  //   const data = this.defineObjectNick({ id: id }, collection, {})
  //   return this.doc(`${opts.path}/${data.id}`).delete();
  // }

  // ***********************************************
  // * Novos Métodos chainable baseados em classes *
  // ***********************************************

  save(config: any): FssSave {
    return new FssSave(config, this.g, this.afs);
  }

  list(config: any): FssList {
    return new FssList(config, this.g, this.afs);
  }

  read(config: any): FssRead {
    return new FssRead(config, this.g, this.afs);
  }

  delete(config: any): FssDelete {
    return new FssDelete(config, this.g, this.afs);
  }
}

// ***********
// * CLASSES *
// ***********

export class FssBase {
  protected g: GlobalService;
  protected afs: AngularFirestore;
  protected collections: any;
  protected collection;
  protected fs = firebase.firestore();

  constructor(config: any, g: GlobalService, afs: AngularFirestore) {
    this.g = g;
    this.afs = afs;
    if (typeof config === 'string') this.collection = getOpts(config);
    else if (config && config.collection) this.collection = getOpts(config.collection);
    else throw Error('[FSS] No collection found');
  }

  protected col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    // return new FireReference(this.afs).col(ref, queryFn);
    return col(ref, queryFn) as AngularFirestoreCollection<T>;
  }

  protected doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    // return new FireReference(this.afs).doc(ref);
    return doc(ref) as AngularFirestoreDocument<T>;
  }

  protected getUpdatedBy(data: any) {
    const user = this.g.get('user');
    if (user) {
      data.updatedBy = user.email || data.updatedBy || 'guest';
    }
    data.updatedAt = new Date().toISOString();
    return data;
  }

  protected createId() {
    return this.fs.collection('_').doc().id;
  }
}

export class FssList extends FssBase {

  private _where: Array<any[]>;
  private _noNick: boolean;
  private _order: string;
  private _direction: string;
  private _limit: number;

  constructor(config: any, g: GlobalService, afs: AngularFirestore) {
    super(config, g, afs);
    if (typeof config !== 'string') {
      if (config.where) this.where(config.where);
      if (config.orderBy) this.orderBy(config.orderBy, config.orderDirection || 'asc');
      if (config.noNick) this.noNick();
      if (config.limit) this.limit(config.limit);
    }
  }

  where(where?: Array<any[]>): FssList {
    // if (!where || !(where instanceof Array)) throw new Error('[FssList:where] Deve ser um array de wheres clause');
    if (where && where.length) this._where = where;
    return this;
  }

  orderBy(field?: string, direction = 'asc'): FssList {
    // if (!field) throw new Error('[FssList:orderBy] Deve conter o nome de um field a ordenar');
    if (field) this._order = field;
    if (direction) this._direction = direction;
    return this;
  }

  noNick(): FssList {
    this._noNick = true;
    return this;
  }

  limit(limit: number): FssList {
    // if (!limit) throw new Error('[FssList:limit] Deve conter um numero maior que 0');
    this._limit = limit;
    return this;
  }

  @catchObsError()
  obs(): Observable<any[]> {
    const list = this.col(this.collection.path, ref => {
      if (this._where && this._where !== null) {
        if (this._where instanceof Array) {
          for (const where of this._where) {
            // if (where[2] === undefined)
            //   throw new Error(`Consulta na collection '${this.collection.path}' não pode ter um WHERE cujo valor seja UNDEFINED (${where[0]} ${where[1]} undefined)`);
            // else
            ref = ref.where(where[0], where[1], where[2]);
          }
        }
      }
      if (!this.collection.noNick && !this._noNick) {
        ref = ref.where('nick', '==', this.collection.nick);
      }
      if (this._order && this._order !== null) {
        const dir = this._direction && this._direction ? this._direction : 'asc';
        ref = ref.orderBy(this._order, dir);
      }

      if (this._limit) ref = ref.limit(this._limit);
      return ref;
    });
    return list.snapshotChanges().pipe(valuesWithId);
  }

  @catchPromiseError()
  promise(): Promise<any[]> {
    let listpromise: any = this.fs.collection(this.collection.path);
    if (this._where && this._where !== null) {
      if (this._where instanceof Array) {
        for (const where of this._where) {
          // if (where[2] === undefined)
          //   throw new Error(`Consulta na collection '${this.collection.path}' não pode ter um WHERE cujo valor seja UNDEFINED (${where[0]} ${where[1]} undefined)`);
          // else
          listpromise = listpromise.where(where[0], where[1], where[2]);
        }
      }
    }
    if (!this.collection.noNick && !this._noNick) {
      listpromise = listpromise.where('nick', '==', this.collection.nick);
    }
    if (this._order && this._order !== null) {
      const dir = this._direction && this._direction ? this._direction : 'asc';
      listpromise = listpromise.orderBy(this._order, dir);
    }
    if (this._limit) listpromise = listpromise.limit(this._limit);
    return listpromise.get().then(snapshot => {
      const items = [];
      snapshot.docs.forEach((doc: any) => items.push({...doc.data(), id: doc.id}));
      return items;
    });
  }
}

export class FssRead extends FssBase {

  private _id: string;
  private _field: string;
  private _value: any;

  constructor(config: any, g: GlobalService, afs: AngularFirestore) {
    super(config, g, afs);
    if (typeof config !== 'string') {
      if (config && config.id) this.id(config.id);
      if (config && config.getBy) this.getBy(config.getBy, config.getByValue);
    }
  }

  id(id: string): FssRead {
    // if (!id || typeof id !== 'string') throw new Error('[FssRead:id] Deve conter um ID Válido');
    this._id = id;
    return this;
  }

  getBy(field: string, value: any) {
    if (field && value !== undefined) {
      this._field = field;
      this._value = value;
    } // else throw new Error('[FssRead:getBy] Deve conter um campo e um valor');
    return this;
  }

  @catchObsError()
  obs(): Observable<any> {
    // if (!this._id && !this._field) return throwError('[FssRead:exec] Deve conter um ID Válido');
    const read = this.doc(`${this.collection.path}/${this._id}`)
    if (this._field)
      return this.col(this.collection.path, ref => ref.where(this._field, '==', this._value)).snapshotChanges()
        .pipe(valuesWithId, map(results => results.length ? results[0] : null));
    return read.snapshotChanges().pipe(docWithId);
  }

  @catchPromiseError()
  promise(): Promise<any> {
    // if (!this._id && !this._field) {
    //   throw new Error('[FssRead:promise] Deve conter um ID Válido');
    //   return Promise.resolve({error: {message: '[FssRead:promise] Deve conter um ID Válido', class: this.constructor.name, collection: this.collection.path}});
    // }

    if (this._field)
      return this.fs.collection(this.collection.path).where(this._field, '==', this._value).get()
        .then(results => results.docs.length ? ({...results.docs[0].data(), id: results.docs[0].id}) : null);

    return this.fs.collection(this.collection.path).doc(this._id).get().then(result => ({...result.data(), id: result.id}));
  }
}

export class FssSave extends FssBase {

  private values: any;
  private _id: string;
  private _createdAt: string;
  private _merge = true;

  constructor(config: any, g: GlobalService, afs: AngularFirestore) {
    super(config, g, afs);
    if (typeof config !== 'string') {
      if (config && config.id) this.id(config.id);
      if (config && config.data) this.data(config.data);
      if (config && config.hasOwnProperty('merge')) this._merge = config.merge;
    }
  }

  id(id: string): FssSave {
    // if (!id || typeof id !== 'string') throw new Error('[FssSave:id] Deve conter um ID Válido');
    this._id = id;
    return this;
  }

  private createdAt(): FssSave {
    this._createdAt = new Date().toISOString();
    return this;
  }

  data(data: any): FssSave {
    // if (!data || !Object.keys(data).length) throw new Error('[FssSave:data] Deve conter um Objeto');
    this.values = data;
    return this;
  }

  merge(merge: boolean) {
    this._merge = merge;
    return this;
  }

  @catchObsError()
  obs(): Observable<any> {
    return new Observable<any>(obs => {
      this.promise().then(res => {
        if (res.error)
          obs.error(res.error);
        else
          obs.next(res);
        // obs.complete();
      }).catch(err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  @catchPromiseError()
  promise(): Promise<any> {
    // if (!this.values || !Object.keys(this.values).length)
    //     return Promise.resolve({error: {message: '[FssSave:data] Deve conter um Objeto para ser gravado', class: this.constructor.name, collection: this.collection.path}});

    let data: any = defineObjectNick(this.values, this.collection.original);
    data = this.getUpdatedBy(data);
    if (!this._id) this.createdAt();
    if (this._createdAt) data.createdAt = this._createdAt;
    data.id = data.id || this._id || this.createId();

    // if (!data.id) throw new Error('[FssSave:id] Deve conter um ID Válido');

    return this.fs.collection(this.collection.path).doc(data.id).set(data, {merge: this._merge}).then(() => ({id: data.id}));
  }

}

export class FssDelete extends FssBase {

  private _id: string;

  constructor(config: any, g: GlobalService, afs: AngularFirestore) {
    super(config, g, afs);
    if (typeof config !== 'string') {
      if (config && config.id) this.id(config.id);
    }
  }

  id(id: string): FssDelete {
    // if (!id || typeof id !== 'string') throw new Error('[FssDelete:id] Deve conter um ID Válido');
    this._id = id;
    return this;
  }

  @catchObsError()
  obs(): Observable<any> {
    return new Observable<any>(obs => {
      this.promise().then(res => {
        obs.next(res);
        obs.complete();
      }).catch(err => {
        obs.error(err);
        obs.complete();
      });
    });
  }

  @catchPromiseError()
  promise(): Promise<any> {
    // if (!this._id)
    //     return Promise.resolve({error: {message: '[FssDelete:id] Deve conter um ID Válido', class: this.constructor.name, collection: this.collection.path}});
    return this.fs.collection(this.collection.path).doc(this._id).delete().then(() => true);
  }

}
