<!-- set the header navigation by program type -->
<!-- in the future, each type of program should have its own layout file -->
<ng-container [ngSwitch]="program?.type">
  <rewards-nav *ngSwitchCase="'rewards'"></rewards-nav>
  <rewards-nav *ngSwitchCase="'shop'"></rewards-nav>
  <sales-nav *ngSwitchCase="'rewards'"></sales-nav>
  <nav-catalog *ngSwitchCase="'catalog'"></nav-catalog>
  <nav-slideshow *ngSwitchCase="'slideshow'"></nav-slideshow>
  <nav-cashback *ngSwitchCase="'cashback'"></nav-cashback>
  <nav-institutional *ngSwitchCase="'institutional'"></nav-institutional>
</ng-container>

<!-- layout shared components -->

<app-notifications></app-notifications>
<sw-update></sw-update>
<gdpr-verify></gdpr-verify>
<!-- /layout shared components -->

<!-- the correct way to use router-outlet component is standalone -->
<router-outlet></router-outlet>
