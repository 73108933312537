<div class="modal-generic-area" *ngIf="show && config">

  <div class="notify-modal">

    <div class="notify-header">
      {{data.title}}
    </div>

    <div class="notify-body" [innerHTML]="data.message"></div>

    <div class="notify-footer">
      <button class="btn btn-primary btn-sm" type="button" (click)="closeClick()">Fechar</button>
    </div>

  </div>

</div>
