import { NotifyService } from '@shared/notifications/notify.service';
import { GlobalService } from './global.service';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators'
import { Observable } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

export type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
export type DocPredicate<T> = string | AngularFirestoreDocument<T>;
export const valuesWithId = map((actions: any[]) => actions.map((a: any) => ({ ...a.payload.doc.data(), id: a.payload.doc.id })));
export const docWithId = map((action: any) => action.payload.exists ? ({ ...action.payload.data(), id: action.payload.id }) : null);

@Injectable({ providedIn: 'root' })
export class FirestoreService2 {
  subcollections: any = {
    programs: false,
    langs: false,
    cards: false,
    debug: false,
    formsConfig: false,
    transactionsGateway: false,
    versions: 'settings/yes/versions',
    campaignCalendars: 'settings/nick/campaignCalendars',
    campaignTemplates: 'settings/nick/campaignTemplates',
    chains: 'settings/nick/chains',
    storeCategories: 'settings/nick/storeCategories',
    storeDisplays: 'settings/nick/storeDisplays',
    storeTypes: 'settings/nick/storeTypes',
    storeZones: 'settings/nick/storeZones',
  }
  fs: any = null

  constructor(
    public afs: AngularFirestore,
    public g: GlobalService,
    public notify: NotifyService
  ) { this.fs = firebase.firestore(); }

  // pontonos -> program.nicks = { products: 'yes' }
  nick(collection) { return this.g.program.nicks && this.g.program.nicks[collection] ? this.g.program.nicks[collection] : this.g.program.nick }
  path(collection, nick?, subcol?) {
    nick = nick ? nick : this.nick(collection)
    subcol = subcol || null
    let coll
    if (typeof this.subcollections[collection] === 'string')
      this.subcollections[collection].split('/')[1] === 'nick' ? coll = this.subcollections[collection].replace('nick', this.g.nick) : coll = this.subcollections[collection]
    else if (subcol)
      coll = `${collection}/${nick}/${subcol}`;
    else
      coll = nick ? `${collection}/${nick}/${collection}` : `${collection}/${nick}/${collection}`
    return this.subcollections[collection] === false ? collection : coll
  }

  get createId() { return this.fs.collection('_').doc().id; }
  get now() { const d = new Date(); return d.toISOString(); }
  get timestamp() { return firebase.firestore.FieldValue.serverTimestamp() }
  get updatedBy() { return this.g.user ? this.g.user.email : ''; }

  list(collection, args?) {
    const nick = args && args.nick ? args.nick : null
    return this.col(this.path(collection, nick, (args || {}).subcol || null), ref => {
      if (args && args.where) {
        for (const where of args.where) { ref = ref.where(where[0], where[1], where[2]) }
      }
      if (args && args.orderBy) { ref = ref.orderBy(args.orderBy, args.direction ? args.direction : 'asc') }
      if (args && args.limit) { ref = ref.limit(args.limit) }
      return ref;
    }).snapshotChanges()
      .pipe(args && args.take === 1 ? take(args.take + 1) : map(actions => actions), valuesWithId);
  }

  get(collection, id, args?): Observable<any> {
    const nick = args && args.nick ? args.nick : null
    return this.doc(`${this.path(collection, nick, (args || {}).subcol || null)}/${id}`)
      .snapshotChanges()
      .pipe(args && args.take ? take(args.take + 1) : map(actions => actions), docWithId);
  }

  getBy(collection, field, value, args?): Observable<any> {
    const nick = args && args.nick ? args.nick : null
    return this.col(this.path(collection, nick, (args || {}).subcol || null), ref => ref.where(field, '==', value))
      .snapshotChanges()
      .pipe(args && args.take ? take(args.take + 1) : map(actions => actions),
        valuesWithId,
        map(results => results.length ? results[0] : null));
  }

  // to update some fields of a document without overwriting the entire document, use the update() method
  // update will update fields but will fail if the document doesn't exist
  update(collection, id, data, args?): Promise<any> {
    data = JSON.parse(JSON.stringify(data))
    data.updatedAt = this.now;
    data.updatedBy = this.updatedBy;
    return this.fs
      .collection(this.path(collection, (args || {}).nick, (args || {}).subcol))
      .doc(id)
      .update(data)
      .then((result) => { if (!args || !args.silent) this.notify.update(`${collection}: os seus dados foram gravados.`, 'btn-success', 3000); return result })
  }

  // to create or overwrite a single document, use set()
  // set without merge will overwrite a document or create it if it doesn't exist yet
  // when you use set() to create a document, you must specify an ID for the document to create
  // if the document does not exist, it will be created
  // if the document does exist, its contents will be overwritten with the newly provided data, unless you
  // specify that the data should be merged into the existing document
  // If you're not sure whether the document exists, use merge() to merge the new data with any existing document
  // to avoid overwriting entire documents
  set(collection, id, data, args?): Promise<any> {
    data = JSON.parse(JSON.stringify(data))
    data.updatedAt = this.now;
    data.updatedBy = this.updatedBy
    return this.fs.collection(this.path(collection, (args || {}).nick, (args || {}).subcol))
      .doc(id)
      .set(data)
      .then((result) => { if (!args || !args.silent) this.notify.update(`${collection}: os seus dados foram gravados.`, 'btn-success', 3000); return result })
  }

  // merge (set with merge) will update fields in the document or create it if it doesn't exists
  merge(collection, id, data, args?): Promise<any> {
    data = JSON.parse(JSON.stringify(data))
    data.updatedAt = this.now
    data.updatedBy = this.updatedBy
    return this.fs
      .collection(this.path(collection, (args || {}).nick, (args || {}).subcol))
      .doc(id)
      .set(data, { merge: true })
      .then((result) => { if (!args || !args.silent) this.notify.update(`${collection}: os seus dados foram gravados.`, 'btn-success', 3000); return result })
  }

  // sometimes there isn't a meaningful ID for the document, and it's more convenient to let Cloud Firestore
  // auto-generate an ID for you; you can do this by calling add()
  // .add() and .doc().set() are completely equivalent, so you can use whichever is more convenient
  // only difference is that .doc().set() doesn't add createdAt automatically, you have to set it
  add(collection, data, args?): Promise<any> {
    data = JSON.parse(JSON.stringify(data))
    data.createdAt = this.now;
    data.updatedBy = this.updatedBy
    if (!data.hasOwnProperty('active')) data.active = true // added by walter
    return this.fs.collection(this.path(collection, (args || {}).nick, (args || {}).subcol))
      .add(data)
      .then((result) => { if (!args || !args.silent) this.notify.update(`${collection}: os seus dados foram gravados.`, 'btn-success', 3000); return result })
  }


  count(ref: CollectionPredicate<any>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn).snapshotChanges()
      .pipe(map(docs => docs.map(a => a.payload.doc.data().length) as any[]));
  }

  col(ref: CollectionPredicate<any>, queryFn?): AngularFirestoreCollection {
    return typeof ref === 'string' ? this.afs.collection(ref, queryFn) : ref;
  }

  doc(ref: DocPredicate<any>): AngularFirestoreDocument {
    return typeof ref === 'string' ? this.afs.doc(ref) : ref;
  }
}
