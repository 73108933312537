import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "./confirm-modal.component";
import { timeOut } from "@core/utils";

/// Notify users about errors and other helpful stuff
export interface Msg {
  content: string;
  content2: string;
  style: string;
  link: {
    name: string,
    url: string
  }
}

export interface IModalConfig {
  message?: string;
  title?: string;
}

@Injectable({ providedIn: 'root' })
export class NotifyService {

  private _msgSource = new Subject<Msg | null>();
  msg = this._msgSource.asObservable();

  modalEvent = new BehaviorSubject<any>(null);

  constructor(private modalService: NgbModal) { }

  update(content: string, style: 'btn-danger' | 'btn-info' | 'btn-success' | 'btn-warning' | 'btn-link' | 'btn-primary', timeout: number, link?, content2?) {
    const msg: Msg = { content, content2, style, link };
    this._msgSource.next(msg);
    timeOut(() => { this._msgSource.next(null); }, timeout);
  }

  openModal(config: any = {}): any {
    // return this.dialogRef.open(modalComponent, { panelClass: 'notity-generic-modal', ...config });
    this.modalEvent.next(config);
  }

  awaitForNotification(awaitPromise: Promise<string>, type = 'snackbar') {
    awaitPromise.then(msg => {
      if (type === 'snackbar') {
        // this.snackRef.open(msg, 'ok', { duration: 2000 })
        this.update(msg, 'btn-success', 2000)
      } else if (type === 'modal') {
        this.openModal({ data: { title: 'Aviso', message: msg } })
      } else {
        this.update(msg, 'btn-success', 10000)
      }
    })
  }

  clear() {
    this._msgSource.next(null);
  }

  confirm(title: string, message: string, btnOkText: string = 'OK', btnCancelText: string = 'Cancel', dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, { size: dialogSize, keyboard: false, centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return new Promise<boolean>(resolve => {
      modalRef.result.then(result => resolve(result)).catch(() => resolve(false));
    });
  }

  alert(title: string, message: string, btnOkText: string = 'OK', dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, { size: dialogSize, keyboard: false, centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = null;
    return new Promise<boolean>(resolve => {
      modalRef.result.then(result => resolve(result)).catch(() => resolve(false));
    });
  }
}
