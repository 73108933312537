import { GlobalService } from './global.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router';
import { AuthService } from './auth.service'
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { timeOut } from "./utils";
import { NotifyService } from "@shared/notifications/notify.service";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    public auth: AuthService,
    private router: Router,
    private g: GlobalService,
    private notify: NotifyService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.verifySoftwareUpdate();
    return this.verifyProceed(next, state);

    // if (this.g.access === 'public') {
    //   return true;
    // } else return this.auth.user.pipe(
    //     take(1),
    //     map(user => !!user),
    //     tap(loggedIn => {
    //       // console.log('# canActivate LoggedIn', loggedIn)
    //       if (!loggedIn) {
    //         // console.log('--> login')
    //         this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    //       } else return true;
    //     })
    //   );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verifyProceed(childRoute, state);
  }

  verifyProceed(next, state): Observable<boolean> | boolean {
    if (this.g.type === 'site' || this.g.type === 'landingPage') return true;
    return this.auth.user.pipe(
      map(user => {
        // console.log('# canActivate LoggedIn', (user || {id: null}).id)
        // console.count('# auth.user.pipe')
        if (!user) {
          console.log('# !user', user, this.g.type)
          // const url = this.g.type === 'shop' ? `/${this.g.type}/home` : '/login';
          // this.router.navigate([url], {queryParams: this.g.type === 'shop' ? {returnUrl: state.url} : null});
          return false;
        } else if (next.data.roles && next.data.roles.indexOf(user.role) === -1) {
          console.log('# roles', next.data.roles)
          this.router.navigate([`/${this.g.type}/home`]);
          this.notify.update('Não tens permissão para aceder a esta página', 'btn-danger', 2000);
          return false;
        } else return true;
      })
    );
  }

  verifySoftwareUpdate() {
    const oldVersion = localStorage.getItem('ov') || null;
    const newVersion = sessionStorage.getItem('nv') || null;

    if (oldVersion !== null && newVersion !== null && +oldVersion < +newVersion) {
      timeOut(() => {
        localStorage.setItem('ov', `${newVersion}`);
        (<any>document).location.reload();
      }, 500);
    }
  }
}
