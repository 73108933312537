<div class="sw-update">

  <div class="sw-modal-backdrop" *ngIf="showModalUpdate && newVersion !== null">

    <div class="sw-modal card">

      <div class="sw-header">
        <i class="fa fa-exclamation-triangle"></i>
        <span>{{'update_available' | transloco}}</span>
      </div>

      <div class="sw-body">
        <p>{{'new_system_update_message' | transloco}}</p>
        <p>{{'version' | transloco}}: <strong>{{newVersion}}</strong></p>
        <p>{{'new_version_action_message' | transloco}}</p>
      </div>

      <div class="sw-footer">
        <button type="button" class="clear await" (click)="updateLater()">{{'await' | transloco}}</button>&nbsp;&nbsp;
        <button type="button" class="activee btn btn-sm btn-primary" (click)="updateNow()">{{'update_now' | transloco}}</button>
      </div>

    </div>

  </div>

  <div class="sw-snackbar" *ngIf="showSnackUpdate && newVersion !== null">
    <span>{{'new_system_update_message' | transloco}} (<strong>{{newVersion}}</strong>), {{'you_need_update' | transloco}}</span>
    <button class="btn" (click)="updateNow()">{{'update_now' | transloco}}</button>
  </div>

</div>
