<!-- ADBLOCK DETECT MODAL -->
<p-dialog [blockScroll]="true" [showHeader]="false" [(visible)]="display" [closable]="false" [modal]="true" [baseZIndex]="50000" appendTo="body">
  <div class="modal-header mx-auto d-block text-center">
    <h1 class="modal-title " id="groupModalLabel">AdBlock detectado!</h1>
    Notámos que está a usar um adBlock!<br>
    Por favor desactive o Adblock para este site!
  </div>
  <div class="row modal-body text-center">
    <div style="width: 100%;font-size:96px;text-align: center;font-weight: 900;">!</div>
  </div>
  <div class="modal-footer mx-auto d-block text-center">
    <button type="button" class="btn btn-warning" (click)="reload()" data-dismiss="modal">
      <i class="fa fa-refresh" aria-hidden="true"></i>Já desactivei o Adblock!
    </button>
  </div>
</p-dialog>
