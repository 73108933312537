import { Injectable } from '@angular/core';
import * as UTF8 from "crypto-js/enc-utf8";
import * as AES from "crypto-js/aes";
import { env } from "../../environments/environment";

declare var PouchDB;

const toJson = str => str instanceof Object ? JSON.stringify(str) : str;
const fromJson = str => {
  try { return JSON.parse(str); } catch (e) { return str; }
};
const getCryKey = key => env.production ? btoa(
  btoa(key.substr(0, Math.round(key.length / 2))).replace(new RegExp("="), '$')
  + env._cryKey
  + btoa(key.substr(Math.round(key.length / 3))).replace(new RegExp("="), '&')
).replace(new RegExp("=", 'g'), '') : key;

/**
 * Serviço que gerencia o acesso
 * ao localStorage e sessionStorage
 * @service Cry
 */
@Injectable({ providedIn: 'root' })
export class Cry {

  private static _local: any = localStorage;
  private static _session: any = sessionStorage;

  /**
   * SetLocal
   * Método stático usado para armazenar um item no localStorage
   * @param key {string} chave que identificará o valor no Storage
   * @param value {any} valor a ser armazenado
   */
  static setLocal(key: string, value: any, crypt = true): void {
    this._local.setItem(
      getCryKey(key),
      Cry.crypt(toJson(value), env.production ? true : crypt)
    );
  }

  /**
   * GetLocal
   * Método stático usado para recuperar um item no localStorage
   * @param key {string} chave que identificará o valor no Storage
   * @returns {any}
   */
  static getLocal(key: string, crypt = true): any {
    const item = this._local.getItem(getCryKey(key));
    return item ? fromJson(Cry.decrypt(item, env.production ? true : crypt)) : null;
  }

  /**
   * DeleteLocal
   * Método estático que exclui um ítem no localStorage
   * @param key
   */
  static deleteLocal(key: string) {
    this._local.removeItem(key);
  }

  /**
   * SetTemp
   * Método stático usado para armazenar um item temporário, criptografado no sessionStorage
   * @param key {string} chave que identificará o valor no Storage
   * @param value {any} valor a ser armazenado
   */
  static set(key: string, value: any, crypt = false): void {
    this._session.setItem(
      getCryKey(key),
      Cry.crypt(toJson(value), env.production ? true : crypt)
    );
  }

  /**
   * GetTemp
   * Método stático usado para recuperar um item temporário no sessionStorage
   * @param key {string} chave que identificará o valor no Storage
   * @returns {any}
   */
  static get(key: string, crypt = false): any {
    const item = this._session.getItem(getCryKey(key));
    return item ? fromJson(Cry.decrypt(item, env.production ? true : crypt)) : null;
  }

  /**
   * DeleteTemp
   * Método estático que exclui um ítem temporário
   * @param key
   */
  static delete(key: string) {
    // this._session.removeItem(env._cryKey + btoa(key));
    this._session.removeItem(getCryKey(key));
  }

  /**
   * clearAll
   * Método stático que limpa todos os valores no localStorage e sessionStorage
   */
  static clearAll(): void {
    this.clearLocal();
    this.clearSession();
  }

  /**
   * clear
   * Método stático que limpa todos os valores no localStorage
   */
  static clearLocal(): void {
    this._local.clear();
  }

  /**
   * clearTemp
   * Método stático que limpa todos os valores no sessionStorage
   */
  static clearSession(): void {
    this._session.clear();
  }

  /**
   * Encripta uma string para que seja armazenada com segurança
   * no local e session storage
   * @param str
   */
  static crypt(str: string, crypt: boolean): string {
    return crypt ? btoa(AES.encrypt(typeof str !== 'string' ? String(str) : str, btoa(env._yesSalt) + btoa(env._yesKey + env._yesSalt)).toString()) : str;
  }

  /**
   * Decripta uma string
   * @param str
   */
  static decrypt(str: string, crypt: boolean): string {
    try {
      return crypt ? AES.decrypt(atob(str), btoa(env._yesSalt) + btoa(env._yesKey + env._yesSalt)).toString(UTF8) : str;
    } catch (e) {
      // console.log(e.message)
      if (e.message && e.message.indexOf("Failed to execute 'atob'") > -1) {
        // console.log(str)
        return str;
      }
      throw new Error(e.message);
    }
  }

  static clearLocalDocs(collection: string): Promise<any> {
    const db = new PouchDB(collection);
    return db.destroy();
  }

  static getLocalDocs(collection: string): Promise<any[]> {
    const db = new PouchDB(collection);
    return db.allDocs({include_docs: true});
  }

  static setLocalDocs(collection: string, data: any[]): Promise<boolean> {
    const db = new PouchDB(collection);
    return db.bulkDocs(data.map(d => {
      for (const prop in d) {
        if (prop.startsWith('_')) delete d[prop];
      }
      return d;
    })); // .map(d => ({...d, _id: d.id}))
  }

}
