import {ErrorHandler, Injectable} from "@angular/core";
import {GlobalService} from "@core/global.service";
import {env} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {first} from "rxjs/operators";
import {BUILD} from "../../environments/build";

@Injectable()
export class YesErrorHandler implements ErrorHandler {

  constructor(private g: GlobalService, private http: HttpClient) {}

  handleError(error: Error) {
    const user = this.g.get('user');
    const err = {
      error: {
        message: error.message,
        stack: error.stack,
        name: error.name
      },
      path: window.location.href,
      user: user ? user.email : 'indefinido',
      nick: this.g.nick,
      timestamp: new Date().getTime(),
      createdAt: new Date().toISOString(),
      build: BUILD
    };

    if (env.production && error.message && error.message.indexOf('INTERNAL ASSERTION FAILED') === -1) {
      this.http.post(`${this.g.apiUrl}/user/___debug___?noAuthRequest`, err).pipe(first()).toPromise();
    } else {
      console.error(error);
    }
  }
}
