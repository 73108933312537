import {Component, EventEmitter, Inject, Input, OnChanges, Output} from '@angular/core';
import {IModalConfig} from "./notify.service";

@Component({
  selector: 'notification-generic-modal',
  templateUrl: './notification-generic-modal.component.html',
  styleUrls: ['./notification-generic-modal.component.scss']
})
export class NotificationGenericModalComponent implements OnChanges {

  @Input() config: any = null;
  @Output() resuult = new EventEmitter<any>();
  @Input() show = false;

  data: IModalConfig = null;

  constructor() { }

  ngOnChanges() {
    if (this.config !== null) {
      this.data = this.config.data;
      // console.log('# show modal', this.show);
    }
  }

  closeClick() {
    this.resuult.emit(null);
  }

}
