import { GlobalService } from './global.service';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
// import { KeysPipe } from '../pipes/keys.pipe';
import { FirestoreService } from '@core/firestore.service'
import * as p from 'polished';
import { THEME } from './theme'
import { AuthService } from './auth.service';

@Injectable()
export class ThemeService {

  constructor(
    public fss: FirestoreService,
    private auth: AuthService,
    private g: GlobalService
  ) { }

  clone(nick?) {
    const _nick = nick || this.g.nick
    this.fss.read('programs').id('pontonos').promise().then(program => {
      if (program && program.colors3) this.fss.save('programs').id(_nick).data({ colors3: program.colors3 }).promise();
    })
  }

  changeTheme(program, nick) {
    const head = (document as any).querySelector('head');
    const style = (document as any).createElement('style');
    style.id = 'css-program';
    let programVars = ':root {';

    // const element = document.documentElement;

    for (const j of ['layout', 'responsiveness', 'typography']) {
      if (program[j]) {
        for (const property in program[j]) {
          if (program[j][property]) {
            // element.style.setProperty(`--${property}`, program[j][property]);
            programVars += `--${property}: ${program[j][property]};`
          }
        }
      } else { program[j] = THEME[j] }
    }

    if (program.colors3) {
      for (const color of program.colors3) {
        // element.style.setProperty(`--${color.name}`, color.value)
        programVars += `--${color.name}: ${color.value} !important;`;
      }
    } else program.colors3 = THEME.colors3
    for (const color of THEME.colorsYES) {
      // element.style.setProperty(`--${color.name}`, color.value)
      programVars += `--${color.name}: ${color.value} !important;`;
    }

    if (program.images && program.images.desktopBg) programVars += `--desktopBg: ${program.images.desktopBg};`
    if (program.images && program.images.mobileBg) programVars += `--mobileBg: ${program.images.mobileBg};`

    style.innerText += programVars + '}';
    head.appendChild(style);
    // console.log('programs', nick, program.nick)
    if (this.g.get('user') && this.g.get('user').role === 'admin') this.fss.save('programs').id(nick).data(program).promise();
    // console.log('theme changed')
    return program
  }

}
