import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {env} from "../../environments/environment";
import {COLLECTIONS} from "@core/firestore.collections";
import {GlobalService} from "@core/global.service";

export type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
export type DocPredicate<T> = string | AngularFirestoreDocument<T>;

export const valuesWithId = map((actions: any[]) => actions.map((a: any) => ({...a.payload.doc.data(), id: a.payload.doc.id})));
export const docWithId = map((action: any) => action.payload.exists ? ({...action.payload.data(), id: action.payload.id}) : null);

let _collections = {};
let _g: GlobalService = null;
let _afs: AngularFirestore = null;

// adapted from http://masteringionic.com/blog/2017-10-22-using-firebase-cloud-firestore-with-ionic/ (PROMISES)
// adapted from https://angularfirebase.com/lessons/firestore-advanced-usage-angularfire/ (OBSERVABLES)

export const col = (ref: CollectionPredicate<any>, queryFn?): AngularFirestoreCollection => {
  return typeof ref === 'string' ? _afs.collection(ref, queryFn) : ref;
};

export const doc = (ref: DocPredicate<any>): AngularFirestoreDocument => {
  return typeof ref === 'string' ? _afs.doc(ref) : ref;
};

export const getOpts = (collection: string, settingType?: string) => {
  const nick = _g.get('program').nicks && _g.get('program').nicks[collection] ? _g.get('program').nicks[collection] : _g.get('nick');
  const collections: any = _collections = COLLECTIONS(nick, settingType);
  const legacy = collections[collection] ? collections[collection][collections[collection].length - 1] : null;
  const noNick = collections[collection] ? collections[collection][collections[collection].length - 2] : null;
  const path = getPath(collection, legacy);

  return {path: path.path, nick, noNick, legacy, subcollection: path.subcollection, original: collection};
};

export const defineObjectNick = (data: any, collection: string, options: any = {}) => {
  const nick = getNick(collection);
  if (!getOpts(collection).noNick) data.nick = data[nick] || nick;
  return JSON.parse(JSON.stringify({...data}));
};

export const getNick = (collection?) => {
  // get a nick for a sepecific collection which is different from the nick of the program (ie, cards, categories, products, sellers, ...)
  // console.log('getNick: ', collection, this.g.get('program').nicks && this.g.get('program').nicks[collection] ? this.g.get('program').nicks[collection] : this.g.get('nick'))
  return _g.get('program').nicks && _g.get('program').nicks[collection] ? _g.get('program').nicks[collection] : _g.get('nick');
}

export const getPath = (collection, legacy) => {
  if (!_collections.hasOwnProperty(collection) && collection.split('/').length > 0) {
    return {path: collection, subcollection: ''};
  }/* else if (!this.collections.hasOwnProperty(collection)) {
        throw new Error(`A collection [${collection}] não está definida nas conficurações do FSS`);
      }*/
  const selected = _collections[collection] ? _collections[collection] : [collection];
  let path;
  let subcollection = '';
  switch (selected.length - 2) {
    case 1:
      path = `${selected[0]}`;
      break;
    case 2:
      path = legacy ? `${selected[1]}_${selected[0]}` : `${selected[0]}/${selected[1]}/${selected[0]}`;
      break;
    case 3:
      subcollection = selected[2];
      path = legacy ? `${selected[1]}_${selected[0]}` : `${selected[0]}/${selected[1]}/${selected[2]}`;
      break;
    default:
      path = `${selected[0]}`;
  }
  return {path, subcollection};
}

export const forwardErrorFn = (fwError, args) => {
    let forwardError = fwError;
    if (!forwardError && args && args instanceof Array) {
        for (const arg of args) {
            if (arg instanceof Object && arg.forwardError === true) {
                forwardError = true;
            }
        }
    }
    // return forwardError;
    return true;
};

export const parseSuccess = (success, key) => {
    if (success === undefined) {
        if (['list', 'read'].indexOf(key) > -1) {
            throw new Error('Documento não encontrado!');
            return success;
        }
        return {};
    } else {
        return success;
    }
};

export const parseError = (self, err, key, args, forwardError, isPromise = false) => {
    // for (const prop in self) {
    //   if (self[prop] instanceof NotifyService) {
    //     self[prop]['update'](err.message, 'btn-danger', 3000);
    //   }
    // }
    if (!env.production) {
        console.error(`# ${self.constructor.name}.${key}()`, err.message ? err.message : err, args);
        console.error(err);
    }
    return forwardError ? (isPromise ? Promise.resolve({
        error: err.message ? err.message : err
    }) : of({
        error: err.message ? err.message : err
    })) : [];
};

export function catchObsError(forwardError: boolean = false) {
    return function(target: any, key: string, descriptor: any) {
        const originalMethod = descriptor.value;
        descriptor.value = function(...args) {
            forwardError = forwardErrorFn(forwardError, args);
            return originalMethod.apply(this, args)
                .pipe(
                    map(success => parseSuccess(success, key)),
                    catchError(err => parseError(this, err, key, args, forwardError))
                );
        };
        return descriptor;
    };
}

export function catchPromiseError(forwardError: boolean = false) {
    return function(target: any, key: string, descriptor: any) {
        const originalMethod = descriptor.value;
        descriptor.value = function(...args) {
            forwardError = forwardErrorFn(forwardError, args);
            return originalMethod.apply(this, args)
                .then(success => parseSuccess(success, key))
                .catch(err => parseError(this, err, key, args, forwardError, true));
        };
        return descriptor;
    };
}

export const defineGlobal = g => _g = g;
export const defineAfs = afs => _afs = afs;

export interface IArgsList {
  where: Array<any[]>;
  forwardError: boolean;
  orderBy: string;
  direction: string;
  take: number;
  limit: number;
  noNick: any;
}
