import {Component, OnInit} from '@angular/core';
import {Cry} from "../cry.service";
import {GlobalService} from "@core/global.service";

@Component({
  selector: 'gdpr-notify',
  templateUrl: './gdpr-verify.component.html',
  styleUrls: ['./gdpr-verify.component.sass']
})
export class GdprVerifyComponent implements OnInit {

  show = false;

  constructor(public g: GlobalService) { }

  ngOnInit() {
    const accepted = Cry.getLocal('accepted-gdpr');
    this.show = accepted !== null ? !accepted : true;
  }

  accept() {
    this.show = false;
    Cry.setLocal('accepted-gdpr', true);
  }

}
