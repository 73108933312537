import {Injectable} from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  StatusBarStyle,
} from '@capacitor/core';
import {Router} from "@angular/router";
import {GlobalService} from "@core/global.service";
import {ApiService} from "@core/api.service";
import {first} from "rxjs/operators";
import {NotifyService} from "@shared/notifications/notify.service";
import {timeOut} from "@core/utils";
import {FirestoreService} from "@core/firestore.service";
import {HttpClient} from "@angular/common/http";
import {BUILD} from "../../environments/build";
import firebase from 'firebase/app';
import 'firebase/messaging';
import {env} from "../../environments/environment";

const {PushNotifications, Device, StatusBar, Modals} = Plugins;
const {FCMPlugin} = Plugins;

export interface IPushPayload {
  route: string;
  title: string;
  subtitle: string;
  tokens: string[];
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class PushService {

  private deviceToken: string = null;
  private webToken: string = null;
  private device: any = null;
  private messaging: any = null;

  constructor(private router: Router, private g: GlobalService, private api: ApiService, private notify: NotifyService, private fss: FirestoreService, private http: HttpClient) {
  }

  startWebPushNotifications() {
    try {
      this.messaging = firebase.messaging();
    } catch (e) {
      console.log('#', e.message || e);
    }

    if (this.messaging !== null) {
      this.messaging.getToken({vapidKey: env.webPushKey}).then((currentToken) => {
        if (currentToken) {
          console.log('#', currentToken);
          this.webToken = currentToken;
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        if (err.message.indexOf('was not granted') === -1) console.log('An error occurred while retrieving token. ', err);
      });

      this.messaging.onMessage((payload: any) => {
        console.log('# Message received. ', payload);
        this.pushReceived(payload);
      });
    }
  }

  startPushNotifications() {
    console.log('# Initializing startPushNotifications');
    const navBarColor = this.g.program.colors3.find(c => c.name === 'navBg');

    Device.getInfo().then(async info => {
      console.log('# info', info);
      this.device = info;

      if (info.platform === 'android' && navBarColor) {
        await StatusBar.setOverlaysWebView({ overlay: true });
        await StatusBar.setBackgroundColor({ color: navBarColor.value });
        await StatusBar.setStyle({ style: StatusBarStyle.Light });
      }

      if (info.platform === 'ios' || info.platform === 'android') {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermission().then(result => {
          if (result.granted) {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
            // alert('Push not allowed');
          }
        }).catch(err => console.log('#', err.message || err));

        if (this.device.platform === 'ios') {
          FCMPlugin.getToken().then((r) => {
            // alert(`Token ${r.token}`);
            const log = {
              error: {
                message: 'Push Token',
                stack: JSON.stringify(r),
                name: 'Log::Push::Token'
              },
              path: window.location.href,
              user: this.g.get('user') ? this.g.get('user').email : 'indefinido',
              nick: this.g.nick,
              timestamp: new Date().getTime(),
              createdAt: new Date().toISOString(),
              build: BUILD
            };
            this.http.post(`${this.g.apiUrl}/user/___debug___?noAuthRequest`, log).pipe(first()).toPromise();
            this.deviceToken = r.token;
          }).catch((err) => console.log(err));
        } else {
          PushNotifications.addListener('registration', (token: PushNotificationToken) => {
              console.log('Push registration success, token: ' + token.value);
              const log = {
                error: {
                  message: 'Push Token',
                  stack: JSON.stringify(token),
                  name: 'Log::Push::Token'
                },
                path: window.location.href,
                user: this.g.get('user') ? this.g.get('user').email : 'indefinido',
                nick: this.g.nick,
                timestamp: new Date().getTime(),
                createdAt: new Date().toISOString(),
                build: BUILD
              };
              this.http.post(`${this.g.apiUrl}/user/___debug___?noAuthRequest`, log).pipe(first()).toPromise();
              this.deviceToken = token.value;
            });
        }

        PushNotifications.addListener('registrationError', (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        });

        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => this.pushReceived(notification));
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: PushNotificationActionPerformed) => this.pushReceived(notification, true));

      }
    });
  }

  private pushReceived(notification: any, performed = false) {
    if (!performed) {
      // console.log('# notification', notification);
      const data: IPushPayload = {...notification.data} || {};
      if (data.route) {
        // this.notify.confirm('Notificação!', `<strong>${notification.title || data.title}</strong><br>${notification.body || data.subtitle || ''}<br><em>Ver detalhes?</em>`)
        //   .then(result => result && this.router.navigate([data.route]));
        // this.showConfirm(notification.title || data.title, `${notification.body || data.subtitle || ''}\nVer detalhes?`)
        //   .then(result => result && this.router.navigate([data.route]));
        this.showConfirm(notification, data);
      } else {
        // this.notify.update(notification.title || data.title, 'btn-success', 4000);
        // this.notify.alert('Notificação!', `<strong>${notification.title || data.title}</strong><br>${notification.body || data.subtitle || ''}`).then();
        // this.showAlert(notification.title || data.title, notification.body || data.subtitle || '');
        this.showAlert(notification, data);
      }
    } else {
      // console.log('# notification', notification);
      const data = {...notification.notification.data} || {};
      if (data.route) {
        // this.notify.confirm('Notificação!', `<strong>${notification.notification.title || data.title}</strong><br>${notification.notification.body || data.subtitle || ''}<br><em>Ver detalhes?</em>`)
        //   .then(result => result && this.router.navigate([data.route]));
        // this.showConfirm(notification.notification.title || data.title, `${notification.notification.body || data.subtitle || ''}\nVer detalhes?`)
        //   .then(result => result && this.router.navigate([data.route]));
        this.showConfirm(notification.notification, data);
      } else {
        // this.notify.update(notification.notification.title || data.title, 'btn-success', 4000);
        // this.showAlert(notification.notification.title || data.title, notification.notification.body || data.subtitle || '');
        // this.notify.alert('Notificação!', `<strong>${notification.notification.title || data.title}</strong><br>${notification.notification.body || data.subtitle || ''}`).then();
        this.showAlert(notification.notification, data);
      }
    }
  }

  private async showAlert(notification: any, data: any) {
    if (this.device.platform === 'web') {
      this.notify.alert(notification.title || data.title, notification.body || data.subtitle || '').then();
    } else {
      this.showAlert(notification.title || data.title, notification.body || data.subtitle || '');
      await Modals.alert({ title: notification.title || data.title, message: notification.body || data.subtitle || '' });
    }
  }

  private showConfirm(notification: any, data: any) {
    if (this.device.platform === 'web') {
      this.notify.confirm(notification.title || data.title, notification.body || data.subtitle || '', 'Ver detalhes')
        .then(result => result && this.router.navigate([data.route]));
    } else {
      Modals.confirm({ title: notification.title || data.title, message: `${notification.body || data.subtitle || ''}\nVer detalhes?`, okButtonTitle: 'Ver agora' })
        .then(result => result && this.router.navigate([data.route]));
    }
  }

  sendPush(payload: Partial<IPushPayload>) {
    // const push = {...payload, payload: {...payload.data, ...payload}};
    // if (payload.route) push.payload.route = payload.route;
    // delete push.data;
    // delete push.payload.data;
    // delete push.payload.tokens;
    // if (!push.payload.route) delete push.payload.route;

    const push = {
      title: payload.title,
      subtitle: payload.subtitle,
      // icon: 'https://firebasestorage.googleapis.com/v0/b/yes-4-web.appspot.com/o/pontonos%2Ficons%2Fandroid-chrome-192x192.png?alt=media&token=35616a6b-5e70-43a0-9284-d780793fa076',
      tokens: payload.tokens,
      payload: {
        title: payload.title,
        subtitle: payload.subtitle,
        ...payload.data,
      },
      webpush: {
        fcm_options: {
          // link: `https://${this.g.program.url.replace(/https?:\/\//i, '')}${payload.route ? payload.route : ''}`
          link: `http://localhost:4500${payload.route ? payload.route : ''}`
        }
      }
    };
    if (payload.route) push.payload.route = payload.route;
    console.log(push);

    return this.api.post('push/sendPush', push).pipe(first());
  }

  registerDeviceToUser() {
    console.log('# registerDeviceToUser()');
    timeOut(() => {
      const user = this.g.get('user');

      if (this.deviceToken) {
        if (user && user.devices && user.devices[this.device.platform]) {
          if (!user.devices[this.device.platform].find(d => d.token === this.deviceToken)) user.devices[this.device.platform].push({
            token: this.deviceToken,
            device: `${this.device.manufacturer} ${this.device.model}`,
            createdAt: new Date().toISOString()
          });
        } else {
          user.devices = user.devices || {};
          user.devices[this.device.platform] = user.devices[this.device.platform] || [];
          user.devices[this.device.platform].push({
            token: this.deviceToken,
            device: `${this.device.manufacturer} ${this.device.model}`,
            createdAt: new Date().toISOString()
          });
        }
      }

      if (this.webToken) {
        if (user && user.devices && user.devices['web']) {
          if (!user.devices['web'].find(d => d.token === this.webToken)) user.devices['web'].push({
            token: this.webToken,
            device: `browser`,
            createdAt: new Date().toISOString()
          });
        } else {
          user.devices = user.devices || {};
          user.devices['web'] = user.devices['web'] || [];
          user.devices['web'].push({
            token: this.webToken,
            device: `browser`,
            createdAt: new Date().toISOString()
          });
        }
      }

      if (user && user.devices) this.fss.save(`${this.g.type}-users`).id(user.id).data({devices: user.devices}).promise();
    }, 600);
  }
}
