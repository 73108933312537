import { FirestoreService } from '@core/firestore.service';
import { TRANSLOCO_LOADER, TranslocoLoader } from '@ngneat/transloco';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TranslationLoader implements TranslocoLoader {
  constructor(
    // private http: HttpClient,
    private fss: FirestoreService
  ) { }

  getTranslation(langPath: string) {
    return this.fss.read('langs').id(langPath).obs()
  }

}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: TranslationLoader };

