import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {env} from './environments/environment';
// Hammerjs must be imported for gestures
import 'hammerjs';

if (env.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }
    window['ngRef'] = ref;
    try {
      if ('serviceWorker' in navigator && env.production) {
        navigator.serviceWorker.register('/ngsw-worker.js');
      }
    } catch (e) {
    }
  })
// .catch(err => console.log(err));
