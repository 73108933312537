import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth.guard';

const routes: Routes = [
  // { path: 'cashback', loadChildren: () => import('./cashback/module').then(m => m.CashbackModule), canActivate: [AuthGuard], data: { breadcrumb: 'Cahshback' } },
  { path: 'catalog', loadChildren: () => import('@catalog/module').then(m => m.CatalogModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard], data: { breadcrumb: 'Catálogo' } },
  { path: 'motivation', loadChildren: () => import('./motivation/motivation.module').then(m => m.MotivationModule) },
  { path: 'rewards', loadChildren: () => import('@rewards/rewards.module').then(m => m.RewardsModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard], data: { breadcrumb: 'Prémios' } },
  { path: 'sales', loadChildren: () => import('@sales/module').then(m => m.SalesModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard], data: { breadcrumb: 'Vendas' } },
  { path: 'shop', loadChildren: () => import('@shop/shop.module').then(m => m.ShopModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard], data: { breadcrumb: 'Shopping' } },
  { path: 'site', loadChildren: () => import('@site/module').then(m => m.SiteModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard], data: { breadcrumb: 'Site' } },
  { path: 'landingPage', loadChildren: () => import('@landingPage/module').then(m => m.LandingPageModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard], data: { breadcrumb: 'Landing Page' } },

  { path: 'login', loadChildren: () => import('@user/user.module').then(m => m.UserModule), data: { breadcrumb: 'Login', state: 'login' } },
  { path: 'help', loadChildren: () => import('@shared/help-center/module').then(m => m.HelpCenterModule), data: { breadcrumb: 'Help', state: 'help' } },

  // { path: '**', redirectTo: `home`, pathMatch: 'full' }
];
// console.log('AppRoutingModule', routes)
@NgModule({
  imports: [RouterModule.forRoot(routes
    // , { enableTracing: true }
    , { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
