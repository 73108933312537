import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CheckoutComponent} from './checkout';
import {AuthGuard} from '@core/auth.guard';

const routes: Routes = [{
  path: '',
  component: CheckoutComponent,
  data: {menu: false, breadcrumb: 'ENCOMENDA'},
  children: [
    {path: 'cart', loadChildren: () => import('./cart/module').then(m => m.CartModule), canActivate: [AuthGuard], data: {menu: false, breadcrumb: 'Carrinho de Compras'}},
    {
      path: 'addresses',
      loadChildren: () => import('./addresses/module').then(m => m.AddressesModule),
      canActivate: [AuthGuard],
      data: {menu: false, breadcrumb: 'Moradas para Entrega'}
    },
    {
      path: 'order',
      loadChildren: () => import('./order/module').then(m => m.OrderModule),
      canActivate: [AuthGuard],
      data: {menu: false, breadcrumb: 'Confirmação da Encomenda'}
    },
    {path: 'my-orders', loadChildren: () => import('./my-orders/module').then(m => m.MyOrdersModule), canActivate: [AuthGuard]}
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckoutRoutingModule {
}
