import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class StyleLoader {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  loadStyle(nick: string, url: string = null) {
    if (!this.document.getElementById(nick)) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = `${nick}_${new Date().getTime()}`;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      if (url !== null) {
        link.href = url;
      } else {
        link.href = `assets/${nick}/styles.css`;
    }
      link.media = 'all';
      // console.log(link);
      head.appendChild(link);
    }
  }

  loadFont(name: string, url: string) {
    const style = document.createElement('style');
    style.innerText = "@font-face { font-family: '" + name + "'; src: url('" + url + "'); }";
    document.head.appendChild(style);
  }

}
