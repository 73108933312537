export const env = {

  production: false,

  _yesKey: 'dGhlIG9sYXJpYSBzZXJ2aWNlIGtleQ==',

  // yes4test,
  firebaseConfig: {
    apiKey: "AIzaSyAMzgkhtgCb2v2ggRY6pEicHhi6GfaNMwQ",
    authDomain: "yes-4-mkt.firebaseapp.com",
    databaseURL: "https://yes-4-mkt.firebaseio.com",
    projectId: "yes-4-mkt",
    storageBucket: "yes-4-mkt.appspot.com",
    messagingSenderId: "371515043581",
    appId: "1:371515043581:web:7771e43929f557044fd894"
  },

  // yes4web
  // firebaseConfig: {
  //   apiKey: "AIzaSyDNOz0mtiLjxXja3FRbezveNPd46zcFMjU",
  //   authDomain: "yes-4-web.firebaseapp.com",
  //   databaseURL: "https://yes-4-web.firebaseio.com",
  //   projectId: "yes-4-web",
  //   storageBucket: "yes-4-web.appspot.com",
  //   messagingSenderId: "976979609520",
  //   appId: "1:976979609520:web:2ff7db39e134d49bc33875"
  // },

  _cryKey: 'JpYS',

  stripePublishable: 'pk_test_8WLlgoMFsLnKWW1oQG7BVHQ8',

  algolia: {
    appId: '3M8JWUF0PM',
    apiKey: '5501a4a9e7f84050a292cdb5fa22bb12'
  },

  infobip: {
    url: 'https://api.infobip.com/'
  },

  _yesSalt: 'dGhlIG9sYXJpYSBzYWx0ZSBrZXkgc2VydmljZQ==',

  easyPayUrl: 'https://api.healthmanager.app/test/', // 'https://api.test.easypay.pt/2.0/',
  easyPayId: '7cb9b8de-28f6-4c87-aca0-f2afb0f733d7',
  easyPayKey: '6a32abbe-f69e-4693-a010-90605061ceb1',

  webPushKey: 'BHmlB43rCU9pM3NAlJsyz0Tp9kH8uognNBxqYZ_u5p_GLjc5_CdQ1bxcNuKOgaTVy2eaArx0Okq8hE49iXXE1lA'

};
