import { GlobalService } from '@core/global.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'adblock-modal',
  templateUrl: './adblock-modal.component.html',
  styleUrls: ['./adblock-modal.component.sass']
})
export class AdblockModalComponent implements OnInit {

  display = false;

  constructor(
    private g: GlobalService
  ) { }

  ngOnInit() {
    if (document.getElementById('bait').offsetHeight === 0 && this.g.program && this.g.type === 'rewards') {
      this.display = true;
    }
  }

  reload() {
    (<any>window).location.reload();
  }

}
