import { Cry } from '@shared/cry.service';
import firestoreParser from 'firestore-parser';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Validators, FormBuilder } from '@angular/forms';
import { BehaviorSubject, Subject, ReplaySubject } from "rxjs";
import { DeviceDetectorService } from 'ngx-device-detector';
import { capitalize } from './utils'
import { defineGlobal } from "@core/firestore.utils";


@Injectable()
export class GlobalService {
  path
  _menus = {
    info: ['collections', 'versions', 'help', 'terms', 'fixes', 'debug'],
    rewards: ['dashboard', 'calendar', 'tasks', 'goals', 'questionnaires', 'visits', 'priceList', 'promotionsList', 'catalogList'],
    sales: ['dashboard', 'calendar', 'tasks', 'goals', 'questionnaires', 'visits', 'priceList', 'promotionsList', 'catalogList', 'help', 'terms', 'cart', 'ranking', 'userManual'],
    tables: ['collections', 'programs', 'menus', 'categories', 'products', 'promotions', 'fnac', 'sections', 'posts', 'segments', 'prospects', 'users', 'sellers', 'stores', 'cards', 'tables-config', 'forms', 'langs', 'settings', 'goalsTemplate', 'accesses'],
    transactions: ['payments', 'points', 'pointsBought', 'orders', 'purchases', 'purchasesVerification', 'purchaseInvoices', 'batches', 'usersBalances', 'productsStats', 'ranking', 'templates', 'billing', 'cardsReceived', 'transactions-drill-down'],
    events: ['messages'],
    cashbacks: ['requests', 'cbProducts'],
  }
  now = new Date()
  api

  private observables: any = {}
  private data = {};


  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    public deviceService: DeviceDetectorService
  ) {
    this.set('isAdminArea', false)
    this.set('isVideo', false)
    this.set('paymentGateway', null)
    defineGlobal(this)
  }

  set(variable, value) {
    if (variable === 'store') Cry.set('store', value, true)
    if (variable === 'storeId') Cry.set('store', value, true)
    // Cry.set('store', value, true)
    this.data[variable] = value;
    if (this.observables[variable]) {
      this.observables[variable].next(this.data[variable] || Cry.get(variable, true) || null);
    }
  }

  getAll() {
    return this.data
  }

  get(variable) {
    // console.log(Cry.get(variable, true)) // TODO MRS too many iterations
    return this.data.hasOwnProperty(variable) ? this.data[variable] : Cry.get(variable, true)
  }
  get developers() { return ['mrsilva@yesmkt.com', 'waltergandarella@yesmkt.com', 'pedroferreiro@yesmkt.com'] }
  get prevCampaignNick() { return this.data['prevCampaignNick'] }
  get campaignNick() { return this.data['campaignNick'] }
  get campaign() { return this.data['campaign'] }
  get campaigns() { return this.data['campaigns'] }
  get stores() { return this.data['stores'] }
  // get filteredStores() { return this.data['filteredStores'] }
  get mode() { return (<any>document).location.pathname.split('/')[1] }
  get projectId() { return getNickAndProjectId().projectId }
  get apiUrl() { return getNickAndProjectId().apiUrl }
  get displayRatio() { return this.data['displayRatio'] }
  get user() { return this.data['user'] }
  get type() { return this.data['type'] }
  get nick() { return this.data['nick'] }
  get program() { return this.data['program'] }
  get access() { return this.data['access'] }
  get isAdminArea() { return this.data['isAdminArea'] }
  get isVideo() { return this.data['isVideo'] }
  get isMobile() { return this.data['isMobile'] }
  get isDesktop() { return this.data['isDesktop'] }
  get isTablet() { return this.data['isTablet'] }
  get os() { return this.data['deviceInfo'].os }
  get os_version() { return this.data['deviceInfo'].os_version }
  get userAgent() { return this.data['deviceInfo'].userAgent }
  get device() { return this.data['deviceInfo'].device }
  get browser() { return this.data['deviceInfo'].browser }
  get month() { return this.now.toLocaleString('pt-pt', { month: "long" }) }
  get nextMonth() { return this.firstOfNextMonth().toLocaleString('pt-pt', { month: "long" }) }
  get thisWeek() { return this.getWeek(this.now) }
  get thisYearWeek() { return this.now.getFullYear() * 100 + this.getWeek(this.now) }
  get widescreen() { return Math.round((16 / 9) * 10) / 10 }
  get standard() { return Math.round((4 / 3) * 10) / 10 }
  get iPad() { return Math.round((3 / 4) * 10) / 10 }
  get isFullscreen() { return window.innerHeight === window.outerHeight ? true : false }
  get isPortrait() { return window.outerWidth < window.outerHeight ? true : false }
  get isLandscape() { return window.outerWidth > window.outerHeight ? true : false }
  get userRatio() { return Math.round((window.screen.width / window.screen.height) * 10) / 10 }
  get ratio() {
    // console.log(this.userRatio, this.widescreen)
    let ratio
    if (this.userRatio === this.widescreen) {
      ratio = 'widescreen';
    } else if (this.userRatio === this.standard || this.userRatio === this.iPad) {
      ratio = 'standard';
    } else {
      ratio = 'other';
    }
    // console.log(ratio)
    return ratio
  }

  get$(variable: string): BehaviorSubject<any> {
    if (!this.observables[variable] || this.observables[variable] === undefined) {
      this.observables[variable] = new BehaviorSubject(this.data[variable] || Cry.get(variable, true) || null);
    }
    return this.observables[variable];
  }

  clear() {
    return this.data = {}
  }

  delete(variable) {
    return this.data[variable] = null;
  }

  getMenus() {
    const menus = []
    Object.keys(this._menus).forEach(key => {
      menus.push({ path: key, children: this.routify(this._menus[key]) })
    })
    return menus
  }

  getMenusGrp() {
    const menusGrp = {}
    for (const [key, value] of Object.entries(this._menus)) {
      // console.log(key, value)
      const grp = { enabled: [false, [Validators.required as any]] }
      for (const v of value) {
        grp[v] = [false, [Validators.required as any]]
      }
      menusGrp[key] = this.formBuilder.group(grp)
    }
    return menusGrp
  }

  routify = (menuItems: string[], type: string = '') => {
    const routes = [];
    for (const path of menuItems) {
      if (path) {
        const route = {
          path: path,
          loadChildren: () => import(`${type}/${path}/module`).then(m => m[capitalize(path) + 'Module']),
        };
        // if (path !== 'login') { route['canActivate'] = [AuthGuard]; }
        routes.push(route);
      }
    }
    return routes;
  }


  initApp(): Promise<any> {
    this.set('isMobile', this.deviceService.isMobile())
    this.set('isDesktop', this.deviceService.isDesktop())
    this.set('isTablet', this.deviceService.isTablet())
    // console.log(this.deviceService.getDeviceInfo())
    this.set('deviceInfo', this.deviceService.getDeviceInfo())

    // console.log(window.innerHeight)
    this.set('innerWidth', window.innerWidth)
    this.set('innerHeight', window.innerHeight)

    const { nick, projectId, projectName, apiUrl } = getNickAndProjectId();
    // console.log(nick, projectId, projectName, apiUrl)
    this.set('nick', nick)
    this.set('campaignNick', nick)
    // console.log('global service-campaignNick: ', nick)
    this.set('projectId', projectId)
    sessionStorage.setItem('projectId', projectId)
    // console.log(sessionStorage.getItem('projectId'))
    this.set('projectName', projectName)
    this.set('apiUrl', apiUrl)
    const url = 'https://firestore.googleapis.com/v1beta1/projects'
    // console.log(projectId, `${url}/${projectId}/databases/(default)/documents/programs/${nick}`)
    const promise = this.httpClient.get(`${url}/${projectId}/databases/(default)/documents/programs/${nick}`)
      .toPromise()
      .then(res => {
        // console.log(res)
        const program = firestoreParser(res['fields'])
        // console.log(program)
        program.mode = program.type === 'shop' ? 'rewards' : program.type
        this.set('program', program)
        if (program.campaigns) {
          this.set('campaigns', program.campaigns)
          this.set('campaign', program.campaigns[nick])
          // console.log('global service-campaigns: ', program.campaigns, program.campaigns[nick])
        }
        (window as any).programType = program.type;
        this.set('type', program.type)
        this.set('access', program.access)
        // console.log('program definitions fetched')

      }).catch(err => {
        console.log('# ERROR', err.message);
        console.log(`${url}/${projectId}/databases/(default)/documents/programs/${nick}`);
      });
    // console.log(`initializeApp ended ...`);
    return promise;
  }

  getWeek(dt) {
    const tdt: any = new Date(dt.valueOf());
    const dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
  }

  firstOfNextMonth() {
    const d = new Date();
    d.setMonth(d.getMonth() + 1, 1);
    return d;
  }

}

export const getNickAndProjectId = () => {
  const hostname = ((window as any).location.hostname);
  const port = ((window as any).location.port);

  const nick = hostname.split('.')[0];

  let projectId = '';
  let apiUrl = '';
  if (port === '4500') {
    projectId = 'yes-4-mkt';
    apiUrl = `http://localhost:5000/${projectId}/us-central1`;
  } else if (hostname.split('.')[hostname.split('.').length - 1] === 'com' ||
    hostname.split('.')[hostname.split('.').length - 1] === 'pt') {
    projectId = 'yes-4-web';
    apiUrl = `https://us-central1-${projectId}.cloudfunctions.net`;
  } else if (hostname.split('.')[hostname.split('.').length - 1] === 'net') {
    projectId = 'yes-4-mkt';
    apiUrl = `https://us-central1-${projectId}.cloudfunctions.net`;
  }

  // return { nick: 'primebonus', projectId: 'yes-4-web', apiUrl: `http://localhost:5000/yes-4-web/us-central1`, projectName: 'yes-4-web' }
  // return { nick: 'pontonos', projectId: 'yes-4-mkt', apiUrl: `https://us-central1-yes-4-mkt.cloudfunctions.net`, projectName: 'yes-4-mkt' }
  // return { nick: 'primebonus', projectId: 'yes-4-web', apiUrl: `https://us-central1-yes-4-web.cloudfunctions.net`, projectName: 'yes-4-web' }

  return { nick, projectId, apiUrl, projectName: projectId };
}
