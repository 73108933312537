export const COLLECTIONS = (nick, settingType) => ({
  // last property (boolean) is true if collection is legacy, false if not
  // before last property (boolean) is true if noNick, false if not
  // first property is collection, second is either prefix (if last === true) or nick for query (otherwise)
  balances: ['transactions', nick, false, true],
  'rewards-balances': ['transactions', nick, false, false],
  'shop-balances': ['transactions', nick, false, false],
  'catalog-balances': ['transactions', nick, false, false],
  'sales-balances': ['transactions', nick, false, false],
  'site-balances': ['transactions', nick, false, false],
  batches: ['batches', nick, false, true],
  'rewards-batches': ['batches', nick, false, false],
  'shop-batches': ['batches', nick, false, false],
  'catalog-batches': ['batches', nick, false, false],
  'sales-batches': ['batches', nick, false, false],
  'site-batches': ['batches', nick, false, false],
  cards: ['cards', true, false],
  cashbacks: ['cashbacks', nick, false, false],
  'rewards-cashbacks': ['cashbacks', nick, false, false],
  'sales-cashbacks': ['cashbacks', nick, false, false],
  'shop-cashbacks': ['cashbacks', nick, false, false],
  categories: ['categories', 'yes', true, true],
  'rewards-categories': ['categories', 'yes', true, false],
  'shop-categories': ['categories', nick, false, false],
  'catalog-categories': ['categories', nick, false, false],
  'sales-categories': ['categories', nick, false, false],
  'site-categories': ['categories', nick, false, false],
  chats: ['chats', nick, false, false],
  'rewards-chats': ['chats', nick, false, false],
  'shop-chats': ['chats', nick, false, false],
  'catalog-chats': ['chats', nick, false, false],
  'sales-chats': ['chats', nick, false, false],
  'site-chats': ['chats', nick, false, false],
  charts: ['charts', true, false],
  'rewards-charts': ['charts', nick, false, false],
  'shop-charts': ['charts', nick, false, false],
  'catalog-charts': ['charts', nick, false, false],
  'sales-charts': ['charts', nick, false, false],
  'site-charts': ['charts', nick, false, false],
  chartsTemplate: ['settings', 'yes', 'charts', true, false],
  collectionsConfig: ['collectionsConfig', false, false],
  counties: ['settings', 'yes', 'counties', true, false],
  debug: ['debug', true, false],
  districts: ['settings', 'yes', 'districts', true, false],
  events: ['events', nick, false, false],
  'rewards-events': ['events', nick, false, false],
  'shop-events': ['events', nick, false, false],
  'catalog-events': ['events', nick, false, false],
  'sales-events': ['events', nick, false, false],
  'site-events': ['events', nick, false, false],
  formsConfig: ['formsConfig', true, false],
  formsValues: ['formsValues', nick, false, false],
  goals: ['goals', true, false],
  'perfectstore-goals': ['goals', nick, 'perfectstore', false, false],
  'lojaveraoitm-goals': ['goals', nick, 'lojaveraoitm', false, false],
  goalsTemplates: ['goalsTemplates', nick, false, false],
  'goals-templates': ['goals-templates', nick, false, false],
  'sales-goalsTemplates': ['goalsTemplates', nick, false, false],
  'sales-goals-templates': ['goals-templates', nick, false, false],
  langs: ['langs', true, false],
  logs: ['logs', nick, false, false],
  menus: ['menus', false, false],
  paymentsConfig: ['paymentsConfig', true, false],
  posts: ['posts', nick, false, true],
  'rewards-posts': ['posts', nick, false, false],
  'catalog-posts': ['events', nick, false, false],
  'sales-posts': ['posts', nick, false, false],
  'site-posts': ['posts', nick, false, false],
  'shop-posts': ['posts', nick, false, false],
  postalCodes: ['settings', 'yes', 'postalCodes', true, false],
  'shop-postalCodes': ['settings', nick, 'postalCodes', false, false],
  'shop-variants': ['settings', nick, false, false],
  products: ['products', 'yes', true, true],
  'rewards-products': ['products', 'yes', true, false],
  'catalog-products': ['products', nick, false, false],
  'catalog-promotions': ['products', nick, false, false],
  'sales-products': ['products', nick, false, false],
  'site-products': ['products', nick, false, false],
  'shop-products': ['products', nick, false, false],
  cbProducts: ['cbProducts', nick, false, false],
  programs: ['programs', true, false],
  prospects: ['prospects', nick, false, true],
  'rewards-prospects': ['prospects', nick, false, false],
  'catalog-prospects': ['prospects', nick, false, false],
  'sales-prospects': ['prospects', nick, false, false],
  'site-prospects': ['prospects', nick, false, false],
  'shop-prospects': ['prospects', nick, false, false],
  quiz: ['quiz', nick, false, false],
  sections: ['sections', nick, false, true],
  'rewards-sections': ['sections', 'yes', false, true],
  'catalog-sections': ['sections', nick, false, false],
  'sales-sections': ['sections', nick, false, false],
  'site-sections': ['sections', nick, false, false],
  'shop-sections': ['sections', nick, false, false],
  segments: ['segments', nick, false, true],
  'rewards-segments': ['segments', nick, false, false],
  'catalog-segments': ['segments', nick, false, false],
  'sales-segments': ['segments', nick, false, false],
  'site-segments': ['segments', nick, false, false],
  'shop-segments': ['segments', nick, false, false],
  sellers: ['sellers', 'yes', true, true],
  'rewards-sellers': ['settings', 'yes', 'sellers', true, false],
  'catalog-sellers': ['sellers', nick, false, false],
  'sales-sellers': ['settings', 'yes', 'sellers', true, false],
  'site-sellers': ['sellers', nick, false, false],
  'shop-sellers': ['sellers', nick, false, false],
  settings: ['settings', nick, settingType, false, false],
  'sales-campaignTemplates': ['settings', nick, 'campaignTemplates', false, false],
  'sales-campaignCalendars': ['settings', nick, 'campaignCalendars', false, false],
  'sales-storeDisplays': ['settings', nick, 'storeDisplays', false, false],
  'sales-storeZones': ['settings', nick, 'storeZones', false, false],
  'sales-storeCategories': ['settings', nick, 'storeCategories', false, false],
  'sales-chains': ['settings', nick, 'chains', false, false],
  stores: ['stores', nick, false, false],
  'catalog-stores': ['stores', nick, false, false],
  'sales-stores': ['stores', nick, false, false],
  'shop-stores': ['stores', nick, false, false],
  tasks: ['tasks', nick, false, false],
  // 'sales-tasks': ['tasks', nick, false, false],
  templates: ['templates', nick, false, false],
  tablesConfig: ['tablesConfig', true, false],
  transactions: ['transactions', nick, false, true],
  'rewards-transactions': ['transactions', nick, false, false],
  'catalog-transactions': ['transactions', nick, false, false],
  'shop-transactions': ['transactions', nick, false, false],
  'sales-transactions': ['transactions', nick, false, false],
  'site-transactions': ['transactions', nick, false, false],
  transactionsGateway: ['transactionsGateway', true, false],
  typologies: ['settings', nick, 'storeTypes', false, false],
  'sales-storeTypes': ['settings', nick, 'storeTypes', false, false],
  users: ['users', nick, false, true],
  'rewards-users': ['users', nick, false, false],
  'shop-users': ['users', nick, false, false],
  'catalog-users': ['users', nick, false, false],
  'sales-users': ['users', nick, false, false],
  'site-users': ['users', nick, false, false],
  versions: ['settings', 'yes', 'versions', true, false],
  xls: ['settings', nick, 'xls', false, false],
  challenges: ['challenges', nick, false, false]
});
