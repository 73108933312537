import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FirestoreService } from '@core/firestore.service';
import { AuthService } from '@core/auth.service';
import {SubSink} from "subsink";

declare let ga: Function;

@Component({
  selector: 'std-page',
  templateUrl: './page.html',
  styleUrls: ['./page.sass']
})
export class PagesComponent implements OnInit, OnDestroy {
  program: any;
  private subs = new SubSink();

  constructor(
    private router: Router,
    public fss: FirestoreService,
    public auth: AuthService
  ) { }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // console.log(this.program, event.urlAfterRedirects)
          ga('create', this.program.gaTid, 'auto');
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      })
    );
  }

}
