import { FirestoreService } from '@core/firestore.service';
import { LangService } from '@core/lang.service';
import { GlobalService } from '@core/global.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@core/auth.service';
import { ThemeService } from '@core/theme.service'
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StyleLoader } from './style.loader'
import { TranslocoService } from "@ngneat/transloco";
import { objectToArray } from '@core/utils'
import { RoutingService } from '@core/routing.service'
import { Subscription } from 'rxjs/internal/Subscription';
import { SubSink } from "subsink";
import { take } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { doc } from "@core/firestore.utils";
import { PaymentService } from "@checkout/payment-gateway/payment.service";
import { PushService } from "@core/push.service";

declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {

  nick: any;
  program: any;
  type: any;
  isVideo = false;
  displays = [
    { w: 640, h: 360, t: '', r: 0 },
    { w: 568, h: 320, t: '', r: 0 },
    { w: 667, h: 375, t: '', r: 0 },
    { w: 812, h: 375, t: '', r: 0 },
    { w: 1024, h: 768, t: '', r: 0 },
    { w: 1280, h: 704, t: '', r: 0 },
    { w: 1366, h: 1024, t: '', r: 0 },
    { w: 1600, h: 773, t: '', r: 0 },
    { w: 1920, h: 953, t: '', r: 0 },
    { w: 1920, h: 1080, t: '', r: 0 },
    { w: 1920, h: 1092, t: '', r: 0 },
    { w: 1920, h: 1200, t: '', r: 0 },
    { w: 2240, h: 1080, t: '', r: 0 },

  ]
  lang = 'pt';
  private subsLang: Subscription = Subscription.EMPTY;
  private subs = new SubSink();

  constructor(
    public auth: AuthService,
    private titleService: Title,
    private metaService: Meta,
    private theme: ThemeService,
    private router: Router,
    private fss: FirestoreService,
    private deviceService: DeviceDetectorService,
    private style: StyleLoader,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public g: GlobalService,
    private translocoService: TranslocoService,
    private routing: RoutingService,
    private langService: LangService,
    private push: PushService
  ) { }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    if (this.g.program.options.pushNotifications) {
      this.push.startPushNotifications();
      this.push.startWebPushNotifications();
    }
    // if (this.g.type === 'shop' || this.g.type === 'site') {
    //   // this.auth.signOut()
    //   this.auth.anonymousLogin().then(res => {
    //     // console.log('anonymous login')
    //   })
    // }
    // console.log(this.g.getAll())

    // debug em tablets android
    (window as any)['ngNav'] = path => this.router.navigate([path]);

    this.nick = this.g.get('nick');
    this.program = this.g.get('program');
    this.type = this.g.get('type');
    this.subsLang.unsubscribe();
    this.checkState();
    // this.setRoutes(this.program.menus)
    // this.setRoutes2()
    this.loadTranslocoLanguages();
    this.style.loadStyle(this.nick, this.program.cssUrl);
    // console.log(this.program)
    // console.log(this.program.typography)
    this.style.loadFont('fonts', this.program.typography.urlFamilySansSerif);
    this.theme.changeTheme(this.program, this.nick);
    this.applyScripts(this.program);
    this.applySEO(this.program);
    this.applyIcons(this.nick);
    if (this.type === 'catalog' || this.type === 'site') this.adjustSizes();
    window.matchMedia('print').addListener(() => { if (this.type === 'catalog' || this.type === 'site') { this.adjustSizes() } });
    window.addEventListener('resize', () => {
      // TODO MRS remove this later when font loading is working
      if (this.type === 'catalog' || this.type === 'site') { this.adjustSizes() }
    })
    if ((this.type === 'catalog' || this.type === 'site') && !this.deviceService.isDesktop()) { window.dispatchEvent(new Event('resize')) }

    if (window.location.pathname === '/') { this.router.navigate(['/', this.type, 'home']) }

    this.subs.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd && (window as any).hasOwnProperty('gtag')) {
          // console.log(this.program, event.urlAfterRedirects)
          (window as any).gtag('create', this.program.gaTid, 'auto');
          (window as any).gtag('set', 'page', event.urlAfterRedirects);
          (window as any).gtag('send', 'pageview');
        }
      })
    );

    this.auth.createUserVisit()
    // TODO PF check this ... if this is necessary, it should be done in index.html
    // const el2 = document.getElementById('outdated');
    // el2.style.setProperty('height', '0');
  }

  private loadTranslocoLanguages() {
    const nickLang = `${this.lang}-${this.nick}`;
    const availableLangs = ['pt-PT', 'pt-BR', 'en', 'es', nickLang];
    this.translocoService.setAvailableLangs(availableLangs);
    if (availableLangs.indexOf(nickLang) > -1) {
      this.translocoService.setActiveLang(nickLang);
    }
  }

  applySEO(program) {
    this.titleService.setTitle(program.name);
    this.metaService.addTags([
      { name: 'description', content: program.description },
      { name: 'author', content: program.author },
      { name: 'keywords', content: program.keywords },
      { name: 'description', content: program.description }
    ]);
  }

  applyScripts(program) {
    if (program && program.htmlHead) {
      const body = this._document.querySelector('body');
      const inject = document.createElement('div');
      inject.innerHTML = program.htmlHead;
      body.append(inject);
    }
    if (program && program.scriptHead) {
      // tslint:disable-next-line:no-eval
      eval(program.scriptHead);
    }

    // execute google analytics
    if (program && program.gaTid) {
      const gaScript = `(function (i, s, o, g, r, a, m) { i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = 1 * new Date(); a = s.createElement(o), m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m) })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga'); ga('create', '${program.gaTid}', 'auto'); ga('send', 'pageview'); console.log('Analytics instalado');`;
      const gaScript2 = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${program.gaTid}'); console.log('Analytics instalado');`;
      // tslint:disable-next-line:no-eval
      eval(gaScript2);
    }
  }

  applyIcons(nick) {
    // old version on assets
    // this._document.getElementById('apple-touch-icon').setAttribute('href', '/assets/' + nick + '/icons/apple-touch-icon.png');
    // this._document.getElementById('icon32x32').setAttribute('href', '/assets/' + nick + '/icons/favicon-32x32.png');
    // this._document.getElementById('icon16x16').setAttribute('href', '/assets/' + nick + '/icons/favicon-16x16.png');
    // this._document.getElementById('manifest').setAttribute('href', '/assets/' + nick + '/manifest.json');
    // this._document.getElementById('mask-icon').setAttribute('href', '/assets/' + nick + '/icons/safari-pinned-tab.svg');

    // new version on storage
    if (this.program && this.program.icons) {
      for (const icon in this.program.icons) {
        if (this._document.getElementById(icon)) this._document.getElementById(icon).setAttribute('href', this.program.icons[icon]);
      }
    }
    if (this.program && this.program.manifest) {
      // this._document.getElementById('manifest').setAttribute('href', this.program.manifest);
      this._document.getElementById('manifest').setAttribute('href', `/assets/manifest/${nick}.json`);
    }
  }

  calcSizes(w, h) {
    const content = { w: 1920, h: 1080, r: 16 / 9 }
    const display = { w: w, h: h, r: w / h }
    const view = { w: 0, h: 0, r: 0, ml: 0, mr: 0, mt: 0, mb: 0 }
    // console.log(display.w, display.h)
    view.r = display.w / display.h

    if (this.g.isPortrait) {
      view.w = display.w
      view.h = display.h
    } else {
      if (display.h > content.h) {
        view.h = content.h
        view.w = view.h * content.r
        view.mr = view.ml = 0
        view.mt = view.mb = (display.h - content.h) / 2

      } else {
        if (display.r < content.r) {
          view.w = display.w
          view.h = view.w / content.r
          const gapY = display.h - view.h
          // view.w = gapY > 9 * 16 ? view.ml = 9 * 16 : 0
          view.ml = gapY > 9 * 16 ? gapY - 9 * 16 : gapY
        } else {
          view.h = display.h
          view.w = view.h * content.r
          view.mt = view.mb = (display.h - content.h) / 2

        }
      }
      const gapX = display.w - view.w
      view.ml = gapX % (9 * 16)
      view.mr = gapX - view.ml

    }
    return view
  }



  adjustSizes() {
    // const results = []
    // for (const display of this.displays) {
    //   const view = this.calcSizes(display.w, display.h)
    //   results.push([display.w, display.h, view.r, view.w, view.h, view.ml, view.mr, view.mt, view.mb])
    // }
    // console.table(results)

    const display = { w: window.innerWidth, h: window.innerHeight, r: window.innerWidth / window.innerHeight }
    if (window.innerWidth > window.innerHeight) display.w = window.innerWidth; display.h = window.innerHeight; display.r = window.innerWidth / window.innerHeight
    // console.log(display)
    const content = { w: 1920, h: 1080, r: 16 / 9 }
    const view = { w: 0, h: 0, r: 0, mgX: 0, mgY: 0, mgR: 0, mgL: 0, fontSize: 16, ratio: '' }
    view.w = content.r > display.r ? display.w : display.w / display.r * content.r
    view.h = content.r > display.r ? display.w / content.r : display.h
    view.mgX = (display.w - view.w) / 2
    view.mgY = (display.h - view.h) / 2
    view.mgR = view.mgX * 2 > view.h / 5 ? view.h / 5 : view.mgX * 2
    view.mgL = view.mgX * 2 - view.mgR
    view.fontSize = display.r < content.r ? display.h / content.h * 16 : display.w / content.w * 16
    const el = document.documentElement;
    for (const [key, value] of Object.entries(view)) {
      // console.log(`${key}: ${value}`)
      el.style.setProperty(`--${key}`, `${value}px`)
    }
    // view.ratio = (content.w / content.h).toString()
    // el.style.setProperty(`--ratio`, (content.w / content.h).toString())
    this.g.set('view', view)
    // console.log(view)
  }

  // setRoutes2() {
  //   this.routing.buildPaths()
  // }

  setRoutes(menus) {
    // console.log(objectToArray(menus, 'path', 'children', 'enabled', false))
    const routes = objectToArray(menus, 'path', 'children')
    console.log('# routes', JSON.parse(JSON.stringify(routes)))
    for (const path of routes) {
      // console.log(objectToArray(path.children, 'path', '', 'enabled'))
      path.children = this.routing.objectToRoute(path.children, 'path', '', 'enabled', true)
    }
    // console.log(routes)
    const config = []
    config[0] = {}
    config[0]['path'] = this.type
    config[0]['children'] = routes
    // this.router.resetConfig(config)
    // console.log(this.router.config)
  }

  checkState() {
    if (this.program) {
      if (!this.program.colors3) this.theme.clone()
      if (!this.program.defaultLang) this.langService.clone()
    }
  }
}
