<div class="card">
  <div class="modal-header p-1 bg-primary text-white">
    <span class="modal-title">{{title}}</span>
    <button type="button" class="close" (click)="close()"> <span aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="message"></p>
  </div>
  <div class="modal-footer desktop">
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="close()" *ngIf="btnCancelText !== null">{{btnCancelText}}</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="confirmAction();">{{btnOkText}}</button>
  </div>
  <div class="modal-footer mobile">
    <div class="row">
      <div class="col-6">
        <button type="button" class="btn btn-block btn-outline-dark" (click)="close()" *ngIf="btnCancelText !== null">{{btnCancelText}}</button>
      </div>
      <div class="col-6">
        <button type="button" class="btn btn-block btn-primary" (click)="confirmAction();">{{btnOkText}}</button>
      </div>
    </div>
  </div>
</div>
