import { FirestoreService } from './firestore.service';
import { GlobalService } from './global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { EmailService } from "@shared/email.service";
import { UserService } from '@user/user.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
  apiUrl: string;
  count: number;

  constructor(
    private http: HttpClient,
    private spin: NgxSpinnerService,
    private g: GlobalService,
    private emailServ: EmailService,
    private fss: FirestoreService
  ) { }

  chunkArray(myArray, chunk_size) {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }
  // TODO MRS not used for now, neeeds completion
  // fix(collection: string): Observable<any> {
  //   // console.log(collection, `${this.g.apiUrl}/fix`, {collection})
  //   return this.http.post(`${this.g.apiUrl}/fix`, { collection });
  // }

  save(dataType, collection, data, key?): any {
    return new Promise((resolve, reject) => {
      this.spin.show()
      const emailProgram = this.emailServ.emailProgram()
      emailProgram.templateId = this.g.program.emails[dataType.toLowerCase()] || ''
      // console.log(emailProgram.templateId)
      if (dataType === 'Prospects') emailProgram.subject = this.g.program.name + ' | Ativa a tua conta'
      const chunks = this.chunkArray(data, 100)
      const promises = [];
      for (let i = 0; i < chunks.length; i++) {
        promises.push(new Promise((resolve, reject) => {
          const body = {
            nick: this.g.program.nicks[dataType.toLowerCase()] ? this.g.program.nicks[dataType.toLowerCase()] : this.g.nick,
            collection: collection,
            key: key,
            program: emailProgram,
            items: chunks[i],
            path: this.fss.getOpts(collection).path
          };
          // console.log('https://us-central1-yes-4-web.cloudfunctions.net', this.g.apiUrl)
          const endpoint = `${this.g.apiUrl}/dataImport/create${dataType}`
          // console.log(endpoint, body);
          this.http.post(endpoint, body).toPromise().then((data) => {
            resolve(data);
          });
        }));
      }
      Promise.all(promises).then(values => {
        this.spin.hide();
        resolve(values);
      });
    });
  }

  getGeoCode(search: string): Observable<any> {
    return this.http.post(`${this.g.apiUrl}/gmail/geocode`, { search });
  }

  // getScrapUrl(url: string): Observable<any> {
  //   return this.http.post(`${this.g.apiUrl}/fnac/getUrl`, { url });
  // }

  get(endpoint: string): Observable<any> {
    return this.http.get(`${this.g.apiUrl}/${endpoint}`);
  }

  post(endpoint: string, body: any): Observable<any> {
    // console.log(`${this.g.apiUrl}/${endpoint}`, body)
    return this.http.post(`${this.g.apiUrl}/${endpoint}`, body);
  }

  updateFnacProduct(): any {
    console.log('api', `${this.g.apiUrl}/fnac/updateFnacProduct`)
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.g.apiUrl}/fnac/updateFnacProduct`, null).toPromise().then((data) => {
        resolve(data);
      });
    })
  }

  youtube(count): Observable<any> {
    const q = this.generateRandomLetter(3)
    const API_KEY = 'AIzaSyAMzgkhtgCb2v2ggRY6pEicHhi6GfaNMwQ'
    return this.http.get("https://www.googleapis.com/youtube/v3/search?key=" + API_KEY + "&maxResults=" + count + "&part=snippet&type=video&q=" + q)
  }

  generateRandomLetter(length) {
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
