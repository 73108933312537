import { GlobalService } from './global.service';
import { FirestoreService } from './firestore.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LangService {

  constructor(
    private fss: FirestoreService,
    private g: GlobalService
  ) { }

  // TODO MRS Did you see the language definition screen that I redesigned on Mar 18? You have the option to clone a language there as well.
  //  By clicking on the 'New Language' button, the modal that opens asks what other language you want to clone
  //  A little more pliable than here, where the base language is always pt-PT.
  //  Does it allow me to add the functionalities and move the language management logic from the component to the service (ideal)?
  clone(nick?) {
    this.fss.read('langs').id('pt-PT').obs().subscribe(lang => {
      // console.log('langs', 'pt-' + this.g.nick, lang)
      this.fss.save('langs').id('pt-' + this.g.nick).data(lang).promise();
      // TODO MRS What happens if we are creating a new language but we don't want it to be the default language for the current
      //  logged in program? Here the program will always be updated
      const _nick = nick || this.g.nick
      this.fss.save('programs').id(this.g.nick).data({ defaultLang: 'pt-' + _nick }).promise();
    })
  }

}
