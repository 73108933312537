import { MyAccountComponent } from './my-account.component';
import { AuthGuard } from '@core/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes = [
  {
    path: '',
    component: MyAccountComponent,
    children: [
      { path: 'about', loadChildren: () => import('./about/module').then(m => m.AboutModule), data: { menu: false, breadcrumb: 'Acerca'} },
      { path: 'vouchers', loadChildren: () => import('./vouchers/module').then(m => m.VouchersModule), data: { menu: false, breadcrumb: 'Vouchers'} },
      { path: 'profile', loadChildren: () => import('./profile/module').then(m => m.ProfileModule), canActivate: [AuthGuard], data: { menu: false, breadcrumb: 'As minhas informações pessoais'} },
      { path: 'cardsReceived', loadChildren: () => import('./cardsReceived/module').then(m => m.CardsReceivedModule), canActivate: [AuthGuard], data: { menu: false, breadcrumb: 'Cartões Rececionados'} },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountRoutingModule { }
